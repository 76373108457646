

export default function() {
  return [
    /*  {
      title: "Bestellingen",
      to: "/bestellingen",
      htmlBefore: 'shopping-cart',
      htmlAfter: ""
    }, */
    {
      title: "Producten",
      to: "/producten",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/systeem-foto%2Flabel.svg?alt=media&token=94da3c67-cc4f-48bc-8e83-181c2d1369f2',
      htmlAfter: ""
    }, 
    {
      title: "Mijn profiel",
      to: "/profiel",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/systeem-foto%2Fuser.svg?alt=media&token=392e2cd0-7a58-4272-8390-2ab56a59fd15',
      htmlAfter: ""
    },
    {
      title: "Berichten",
      to: "/berichten",
      htmlBefore: 'https://firebasestorage.googleapis.com/v0/b/web-app-bbo-prod.appspot.com/o/systeem-foto%2Fbullhorn-solid.svg?alt=media&token=8ac98d40-2050-447c-8cd6-fd4494702e79',
      htmlAfter: ""
    },    
  ];
}
