import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { set } from 'react-ga';


class ProductsIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products:[],
            categories:[],
            selectedProduct:[],
            isProductSelected:false,
            clientUUID:'-ohlnfwebk',
            city:''
        };
    }

    getProducts(city){
    
        let ref = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid+'/products');
        ref.on('value' , snapshot => {
            this.setState({products:[]})
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
          
                this.setState(prevState => ({
                    products: [...prevState.products, state]
                }))  

                console.log(state.categories)

                let cats = Firebase.database().ref('/cities/'+city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+state.id+'/categories');
                cats.on('value' , snapshot => {
            
                        snapshot.forEach((childSnap) => {
                        let stateFac = childSnap.val();
                        
                        let newCat = {catId:state.id, id:stateFac.id,  name:stateFac.name}
                            this.setState(prevState => ({
                                categories: [...prevState.categories, newCat],
                            }));
                    });
                    
                    })

               

                

        })
    });
    }



    componentDidMount() {
        console.log(Firebase.auth().currentUser.uid)
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid
        })
     

        let ref = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid);
        ref.on('value' , snapshot => {
           
   
            let state = snapshot.val();
                this.setState({
                    city: state.city
                }) 
                this.getProducts(state.city);
            
        });
      

        
    }

    changeActive(product){

        for(let k = 0; k <this.state.products.length; k++){
                this.state.products[k].checked = false;
                this.state.products[k].active = 'not-active';
                
                this.setState({
                    products: this.state.products,
                    selectedProduct: [],
                    isProductSelected:false
                })
               
             
        }

        for(let i = 0; i < this.state.products.length; i++){
            if(this.state.products[i].id === product.id && this.state.products[i].checked === false){
                this.state.products[i].active = 'active';
                this.state.products[i].checked = true;
                this.setState({
                    products: this.state.products,
                    selectedProduct: this.state.products[i],
                    isProductSelected:true
                })
            }else if(this.state.products[i].id === product.id && this.state.products[i].checked === true){
                this.state.products[i].active = 'not-active';
                this.state.products[i].checked = false;
                this.setState({
                    products: this.state.products,
                })
            }
        }


      
     
        
       /* this.setState({
            products:this.state.products.filter(item => item.id !== product.id),
        })

        let newProduct = {id:product.id,name:product.name, status:product.status,stock: product.stock,category:product.category,active:'active'}
        this.setState(prevState =>({
            products: [...prevState.products,newProduct]
        }))*/
    }


    deleteProduct(){
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.state.selectedProduct.id).remove();
        this.setState({
            products:this.state.products.filter(item => item.id !== this.state.selectedProduct.id),
            selectedProduct:[],
            isProductSelected:false
        });

        
    }

    

    render() {
        return (
            <div>
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                    {this.state.isProductSelected ?
                    <div>
                    <Link to={{pathname: `/producten/aanpassen`,state: {data: this.state.selectedProduct}}} >Product bewerken</Link>
                    <Button className="delete-button" onClick={() => this.deleteProduct()}>Verwijder product</Button></div>:null}
                    <Link className="align-button-right" to={{pathname: `/producten/toevoegen`}} >Product toevoegen</Link>
                </Col>
                <Col sm={12} className="products-table">
                
            <table>
                <tr>
                    <th><input type="checkbox" disabled/></th>
                    <th>Productnaam</th>
                    <th>Status</th>
                    <th>Voorraad</th>
                    <th>Categorie</th>
                </tr>

                {this.state.products.map((product)=>(
                    <tr className={product.active}>
                    <td><input type="checkbox" checked={product.checked} onClick={()=>this.changeActive(product)}/></td>
                    <td>{product.name}</td>
                    <td>{product.status}</td>
                    <td>{product.stock}</td>
                    <td>{  this.state.categories.filter(category => product.id == category.catId).slice(0,1).map((category,i)=>(category.name))}</td>
                </tr>
                ))}
                
            
            </table>
</Col>
   
            </Row>
            </div>



        );
    }
};

export default ProductsIndex;