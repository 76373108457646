import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Afvalstroom from '../../../assets/images/materialen/afval-dummy.jpg';
import Grondbank from '../../../assets/images/materialen/dirix-grondwerk.jpg';

class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeRow: true,
            activeIs:true
        };
    }

    handChangeClick(item){
        console.log(item);
        if(item === 'grond'){
            this.setState({
                activeRow:false,
                activeIs:true
            })
        }else{
            this.setState({
                activeRow:false,
                activeIs:false
            })
        }
    }



    render() {
        return (
            this.state.activeRow ?
            <Row className="services-section">
                <Col sm={6} className="services-block">
                    <a  onClick={(event)=> this.handChangeClick('grond')}>
                        <div className="background-image" style={{background: `url(${Grondbank}),linear-gradient(70deg, rgba(38, 98, 113, 0.8), rgba(128, 128, 128, 0.8)`}}>
                            <h2>Grond afvoeren</h2>
                        </div>
                    </a>
                </Col>
                <Col sm={6} className="services-block">
                    <a onClick={(event)=> this.handChangeClick('afval')}>
                        <div className="background-image" style={{background: `url(${Afvalstroom}),linear-gradient(70deg, rgba(38, 98, 113, 0.8), rgba(128, 128, 128, 0.8)`}}>
                            <h2>Afval stromen</h2>
                        </div>
                    </a>
                </Col>
                
           </Row>
           :

           this.state.activeIs ?

           <Row className="services-section active-select">
           <Col sm={5} className="services-block" style={{padding:0, marginTop:50}}>
         
                   <div className="background-image" style={{background: `url(${Grondbank}),linear-gradient(70deg, rgba(38, 98, 113, 0.8), rgba(128, 128, 128, 0.8)`}}>
               
                   </div>
    
           </Col>
           <Col sm={4} className="services-section-active">
               <h2>Grond Afvoeren</h2>
               <img className="hr-line" src={require('../../../assets/images/svg/hr-line.svg')} />
               <p>
               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.<br /><br />
               Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.<br />
               </p>
               <a className="ros-button-cta" href="#">
                           Prijslijst
                       </a>

           </Col>
           <Col sm={3} className="services-block" style={{padding:0, marginTop:50}}>
               <a onClick={(event)=> this.handChangeClick('afval')}>
                   <div className="background-image" style={{background: `url(${Afvalstroom}),linear-gradient(70deg, rgba(38, 98, 113, 0.8), rgba(128, 128, 128, 0.8)`}}>
                       <h2>Afval stromen</h2>
                   </div>
               </a>
           </Col>
           
      </Row>
      : 
      
      <Row className="services-section active-select">
            <Col sm={5} className="services-block" style={{padding:0, marginTop:50}}>
            
                    <div className="background-image" style={{background: `url(${Afvalstroom}),linear-gradient(70deg, rgba(38, 98, 113, 0.8), rgba(128, 128, 128, 0.8)`}}>
                
                    </div>

            </Col>
            <Col sm={4} className="services-section-active">
                <h2>Afval stromen</h2>
                <img className="hr-line" src={require('../../../assets/images/svg/hr-line.svg')} />
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.<br /><br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.<br />
                </p>
                <a className="ros-button-cta" href="#">
                            Prijslijst
                        </a>

            </Col>
            <Col sm={3} className="services-block" style={{padding:0, marginTop:50}}>
                <a onClick={(event)=> this.handChangeClick('grond')}>
                    <div className="background-image" style={{background: `url(${Grondbank}),linear-gradient(70deg, rgba(38, 98, 113, 0.8), rgba(128, 128, 128, 0.8)`}}>
                        <h2>Grond afvoeren</h2>
                    </div>
                </a>
            </Col>
            
        </Row>
        );
    }
};

export default Services;