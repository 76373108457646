import React, { Component,useState, useEffect  } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";



class CenterColum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount(){
        console.log('hoogte is:'+window.innerHeight)
    }

    



    render() {
        return (
                <Col  sm={8} >
                    
                </Col>

  
        );
    }
};

export default CenterColum;