import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class bclComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
           <Col sm={12} className="second-home-section" id="bcl">
               <Row>
                   <Col sm={6} className="second-home-text-section">
                       <h2>Betoncentrale Limburg</h2>
                       <img className="hr-line" src={require('../../../assets/images/svg/hr-line.svg')} />
                       <p>
                       In samenwerking met Fertigbeton Rheinland en Dohmen Beton is Betoncentrale Limburg gevestigd op terrein van Recycling Overslag Stein. Dirix speelt een grote rol wat betreft transport van het beton en in het bevoorraden van deze fabriek. Betonsoorten en mixers zijn in soorten beschikbaar. Neem contact op met de centrale voor meer informatie.  
                       </p>
                       <a className="ros-button-cta" target="_blank" href="https://www.bcl-beton.nl/beton-uit-limburg">
                           Contact opnemen
                       </a>


                   </Col>
                   <Col sm={6} className="second-home-image-section">
                        <img src={require('../../../assets/images/materialen/beton.jpg')} />
                    </Col>
                    <Col sm={12} className="table">
                       
                    </Col>
               </Row>
           </Col>
        );
    }
};

export default bclComponent;