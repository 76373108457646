import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class AfvoerenComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step2:false,
            particulier:false,
            niet:false,
            step3:false,
            sidenodeHome:false,
            sidenodeOther:false,
            sidenodeDirix:false,
            vbo:false,
            ap04:false,
            geen:false,
            ap04clicked:false,
            vboclicked:false,
            noResearch:false,
            particulierActive:false,NotparticulierActive:false,

            grond:Boolean
        };
    }




    render() {
        return (
           <Col sm={12} className="second-home-section" id="afvoeren">
               <Row>
                
                    <Col sm={12} className="table">
                    <h2 className="intro">HOE WERKT HET AFVOEREN VAN GROND?</h2>
                    <p className="intro">Doorloop dit stappen plan even. In een paar eenvoudige stappen weet u precies waar u rekening mee moet houden. Wij zetten voor u op het eind alles even op een rijtje. </p>
                    <hr className="hr-sub"/>
                    <h4>Stap 1. Selecteer waar u de grond wilt ontgraven.</h4>
                    <Row className="ground-row">
                       
                        {this.state.particulier ?
                        <Col sm={5}  onClick={()=>this.setState({step2:true, particulier:true, niet:false})} className="particulier-row information-row active half-step">
                         <span className="side-note" onClick={()=>this.setState({sidenodeHome:true})}><i class="fas fa-info-circle"></i></span>
                        <a href="#step-2" className="smoot-link">  <h5>De grond wordt ontgraven uit:</h5>
                             <ul>
                                <li>Tuin</li>
                                <li>Weiland</li>
                                <li>Anders, maar niet onder een verharding</li>
                            </ul>
                            
                            </a>
                        </Col> :
                         this.state.NotparticulierActive ?
                        <Col sm={5}  onClick={()=>this.setState({step2:true, particulier:true, niet:false,particulierActive:true})} className="particulier-row information-row opacity-added half-step">
                         <span className="side-note" onClick={()=>this.setState({sidenodeHome:true})}><i class="fas fa-info-circle"></i></span>
                        <a href="#step-2" className="smoot-link">  <h5>De grond wordt ontgraven uit:</h5>
                             <ul>
                                <li>Tuin</li>
                                <li>Weiland</li>
                                <li>Anders, maar niet onder een verharding</li>
                            </ul>
                            
                            </a>
                        </Col>:<Col sm={5}  onClick={()=>this.setState({step2:true, particulier:true, niet:false,particulierActive:true})} className="particulier-row information-row half-step">
                        <span className="side-note" onClick={()=>this.setState({sidenodeHome:true})}><i class="fas fa-info-circle"></i></span>
                        <a href="#step-2" className="smoot-link">  <h5>De grond wordt ontgraven uit:</h5>
                             <ul>
                                <li>Tuin</li>
                                <li>Weiland</li>
                                <li>Anders, maar niet onder een verharding</li>
                            </ul>
                            
                            </a>
                        </Col>}
                        <Col sm={1} ></Col>
                        {this.state.niet ? <Col sm={5} onClick={()=>this.setState({step2:true, particulier:false,niet:true,NotparticulierActive:true})}  className="openbaar-row half-step information-row out-row active">
                        <span className="side-note" onClick={()=>this.setState({sidenodeOther:true})}><i class="fas fa-info-circle"></i></span>
                        <a href="#step-2" className="smoot-link"> <h5 >De grond ontgraven uit:</h5>
                        <ul>
                                <li>Onder een verharding</li>
                                <li>Tuin met geroerde grond</li>
                                <li>Een industriegebied</li>
                                <li>Berm langs de weg</li>
                            </ul>
                     </a>
                        </Col>:
                        this.state.particulierActive ?
                        <Col sm={5} onClick={()=>this.setState({step2:true, particulier:false,niet:true,NotparticulierActive:true})}  className="openbaar-row information-row half-step out-row opacity-added">
                           <span className="side-note" onClick={()=>this.setState({sidenodeOther:true})}><i class="fas fa-info-circle"></i></span>
                        <a href="#step-2" className="smoot-link"> <h5 >De grond ontgraven uit:</h5>
                        <ul>
                                <li>Onder een verharding</li>
                                <li>Tuin met geroerde grond</li>
                                <li>Een industriegebied</li>
                                <li>Berm langs de weg</li>
                            </ul>
                     </a>
                        </Col>:  <Col sm={5} onClick={()=>this.setState({step2:true, particulier:false,niet:true,NotparticulierActive:true})}  className="openbaar-row half-step information-row out-row">
                        <span className="side-note" onClick={()=>this.setState({sidenodeOther:true})}><i class="fas fa-info-circle"></i></span>
                        <a href="#step-2" className="smoot-link"> <h5 >De grond ontgraven uit:</h5>
                        <ul>
                                <li>Onder een verharding</li>
                                <li>Tuin met geroerde grond</li>
                                <li>Een industriegebied</li>
                                <li>Berm langs de weg</li>
                            </ul>
                     </a>
                     </Col>
                        }
                        {this.state.sidenodeHome ? 
                        <Col sm={4} className="sidenote-block">
                            <p>
                                Met ‘niet onder verharding’ bedoelen wij: ongeroerde grond, grasland of een buitengebied.
                            </p>
                            <p><span className="text-color-red">Let op!</span> Uitzonderingen zijn:
                                <ul>
                                    <li>Geroerde grond, daarmee zeggende dat de grond bewust of onbewust is bewerkt, gemengd of samengevoegd; </li>
                                    <li>Indien het onder een verharding vandaan komt; </li>
                                    <li>Indien het adres zich bevindt in Eijsden, Limburg; </li>
                                    <li>Indien het adres zich in een industriegebied bevindt; </li>
                                </ul>
                            </p>
                            <span className="close-link" onClick={()=>this.setState({sidenodeHome:false})}>Sluiten</span>
                        </Col>:null}
                        {this.state.sidenodeOther ? 
                        <Col sm={4} className="sidenote-block">
                            <p>
                            Grond welke onder een verharding vandaan komt, is altijd verdacht. Omdat in de eerste 0,5m onder de verharding altijd iets van grondverbetering heeft plaats gevonden. Het betreft in dit geval dus geroerde grond.  
                            </p>
                            <p> U kiest ook voor deze optie indien:
                                <ul>
                                    <li>Het adres zich bevindt in Eijsden; </li>
                                    <li>Het adres zich in een industriegebied bevindt; </li>
                                </ul>
                            </p>
                            <span className="close-link" onClick={()=>this.setState({sidenodeOther:false})}>Sluiten</span>
                        </Col>:null}
                    </Row>
{this.state.step2 ?
                    <Row id="step-2" >
                
        {this.state.particulier ?
        <Row >
            <Col sm={12} ></Col>
                    <hr/>
                    <Col sm={12} className="set-default-margin">
                            <h4>Stap 2. U kunt uw gegevens indienen bij Dirix.</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                        
                        
                        <p>Uw grond is onverdacht en kan ingenomen worden bij onze grondbank op basis van de Bodemkwaliteitskaart ‘BKK’. Op het oog is niet zien of grond ‘schoon’ is. Om die reden controleren wij de kwaliteit van uw grond. Hiervoor is de locatie van herkomst vereist.</p>
                        </Col>
                        
                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap 3. Uw grond wordt beoordeeld door Dirix.</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                         <span className="side-note" onClick={()=>this.setState({sidenodeDirix:true})}><i class="fas fa-info-circle"></i></span>
                        
                        <p>Op basis van de herkomst van de grond kunnen wij beoordelen, onder welke kwaliteit uw grond mag worden ingenomen. Houdt er rekening mee dat deze beoordeling 1 tot 2 werkdagen kan duren.</p>
                        </Col>
                        
                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap 4. Aanmelden afvoeren grond</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                        <p></p>
                        
                        <p>Als de kwaliteit van uw grond is bepaald, kunt u de grond aanmelden bij ROS. U kunt hiervoor een formulier invullen of digitaal aanvragen. Wij gebruiken hiervoor de reeds ingevulde gegevens. </p>
                        </Col>

                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap. 5 Ontgraven van grond</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                        
                        <p>Als de kwaliteit van de grond bepaald is en de grond is aangemeld bij ROS krijgt u een uniek afvasltroomnummer. Tijd om aan de slag te gaan. Let er wel op! Als u gaat graven in grond dient u een KLIC-melding te doen. Klik hier: <a href="https://www.kadaster.nl/" target="_blank">https://www.kadaster.nl/</a> voor meer informatie.</p>
                        </Col>
                        
                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap. 6 Transporteren grond</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                        
                        <p>Iedere vracht grond dient voorzien te zijn van een  volledig ingevulde begeleidingsbrief met een uniek afvalstroomnummer. Dit geldt niet voor particulieren met eigen aanhangers. </p>
                        </Col>
                       
                        
                        {this.state.sidenodeDirix ? 
                        <Col sm={4} className="sidenote-block step-three">
                            <p>
                            Op basis van de bodemkwaliteitskaart kan uw grond de volgende klasse hebben: AW2000, Wonen en Industrie. Let op, dit zijn gebruikelijke termen in de branche en hebben niks met uw adres te maken. De kwaliteit van grond kan bepalend zijn voor de stortprijs.   

                            </p>
                           
                            <span className="close-link" onClick={()=>this.setState({sidenodeDirix:false})}>Sluiten</span>
                        </Col>:null}
                        </Row>
                        :
                        <Col sm={12}>
                            <Row >
                            <hr/>
                                <Col sm={12} className="set-default-margin">
                            <h4>Stap 2. U dient te beschikken over een rapport</h4>
                            </Col>
                            {this.state.vboclicked ? <Col sm={3} className="left-block set-default-margin outline information-row full-row-step half-step active">
                        <span className="side-note" onClick={()=>this.setState({vbo:true})}><i class="fas fa-info-circle"></i></span>
                        <a href="#step-3" className="smoot-link">  <h6 onClick={()=>this.setState({vboclicked:true, ap04clicked:false,noResearch:false})}>Ik heb verkennend bodemonderzoek</h6>
                       
                        </a>
                        </Col>:
                        <Col sm={3} className="left-block half-step  set-default-margin outline information-row full-row-step">
                        <span className="side-note" onClick={()=>this.setState({vbo:true})}><i class="fas fa-info-circle"></i></span>
                        <a href="#step-3" className="smoot-link">  <h6 onClick={()=>this.setState({vboclicked:true, ap04clicked:false,noResearch:false})}>Ik heb verkennend bodemonderzoek</h6>
                       
                        </a>
                        </Col>}
                        {this.state.ap04clicked ?
                        <Col sm={3} className="set-default-margin half-step outline information-row full-row-step active">

                        <a href="#step-3" className="smoot-link">  <h6 onClick={()=>this.setState({vboclicked:false, ap04clicked:true,noResearch:false})}>Ik heb een partij keuring</h6>
                       
                       </a>
                        </Col>
                        :
                        <Col sm={3} className="set-default-margin half-step outline information-row full-row-step">

                        <a href="#step-3" className="smoot-link">  <h6 onClick={()=>this.setState({vboclicked:false, ap04clicked:true,noResearch:false})} href="#test">Ik heb een partij keuring</h6>

                       </a>
                        </Col>

    }
    {this.state.noResearch ?<Col sm={3} className="set-default-margin outline information-row full-row-step half-step active">

                       <a href="#text" className="smoot-link"> <h6 onClick={()=>this.setState({vboclicked:false, ap04clicked:false,noResearch:true})} >Ik heb geen onderzoek</h6>
                       </a>
                      
                        </Col>
        :
        <Col sm={3} className="set-default-margin outline information-row full-row-step half-step">
                      
                       <a href="#text" className="smoot-link"> <h6 onClick={()=>this.setState({vboclicked:false, ap04clicked:false,noResearch:true})} >Ik heb geen onderzoek</h6>
</a>
                        
            </Col>

}
                        
                        <hr/>

                        {this.state.vbo ? 
                        <Col sm={4} className="sidenote-block  vbo">
                            <p>
                            Op basis van een verkennend bodemonderzoek (VBO) kan grond afgevoerd worden naar een grondbank zoals Recycling Overslag Stein. Mits uit het rapport blijkt dat dit kan natuurlijk. 

                            </p>
                           
                            <span className="close-link" onClick={()=>this.setState({vbo:false})}>Sluiten</span>
                        </Col>:null}
                        {this.state.ap04 ? 
                        <Col sm={4} className="sidenote-block  vbo">
                            <p>


                            </p>
                           
                            <span className="close-link" onClick={()=>this.setState({ap04:false})}>Sluiten</span>
                        </Col>:null}
                        {this.state.geen ? 
                        <Col sm={4} className="sidenote-block  vbo">
                            <p>
                            Op basis van de bodemkwaliteitskaart kan uw grond de volgende klasse hebben: AW2000, Wonen en Industrie. Let op, dit zijn gebruikelijke termen in de branche en hebben niks met uw adres te maken. De kwaliteit van grond kan bepalend zijn voor de stortprijs.   

                            </p>
                           
                            <span className="close-link" onClick={()=>this.setState({geen:false})}>Sluiten</span>
                        </Col>:null}

       
                            {/*<Col sm={4} onClick={()=>this.setState({step3:true, grondbank:true})} className="particulier-row " >
                                <img src={require('../../../assets/images/materialen/dummy-image.jpg')} />
                                <h4></h4>
                            </Col>
                            <Col sm={4} onClick={()=>this.setState({step3:true, grondbank:false})} className="particulier-row " >
                                <img src={require('../../../assets/images/materialen/dummy-image.jpg')} />
                                <h4>Ik heb AP04 onderzoek</h4>
                            </Col>
                            <Col sm={4} onClick={()=>this.setState({step3:true, grondbank:false})} className="particulier-row " >
                                <img src={require('../../../assets/images/materialen/dummy-image.jpg')} />
                                <h4>Ik heb geen onderzoek</h4>
                        </Col>*/}
                                
                            </Row>

                         {this.state.vboclicked ?   <Row id="step-3">
                         <Col sm={12} className="set-default-margin">
                            <h4>Waar kunt u met de grond naar toe?</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                        
                        
                        <p>Met een VBO kunt u grond storten bij een grondbank zoals Recycling Overslag Stein. Uiteraard indien de grond voldoet aan de gestelde eisen.</p>
                        </Col>
                        <hr/>
                         <Col sm={12} className="set-default-margin">
                            <h4>Stap 3. U kunt uw gegevens indienen bij Dirix.</h4>
                            </Col>
                        <Col sm={10} className="set-default-margin outline full-row-step">
                        
                        
                        <p>Uw grond wordt beschouwd als verdachte grond en kan dus enkel op basis van een rapport ingenomen worden. U kunt hiervoor een formulier invullen of digitaal opvragen.</p>
                    
                        </Col>
                        
                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap 4. Uw grond wordt beoordeeld door Dirix.</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                         <span className="side-note" onClick={()=>this.setState({sidenodeDirix:true})}><i class="fas fa-info-circle"></i></span>
                        
                        <p>Op basis van de herkomst van de grond en uw rapport, kunnen wij beoordelen, onder welke kwaliteit uw grond mag worden ingenomen. Houdt er rekening mee dat het beoordelen van sommige rapporten enkele dagen in beslag kan nemen.</p>
                        </Col>
                        
                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap 5. Aanmelden afvoeren grond</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                        <p></p>
                        
                        <p>Als de kwaliteit van uw grond is bepaald, kunt u de grond aanmelden bij ROS. U kunt hiervoor een formulier invullen of digitaal aanvragen. Wij gebruiken hiervoor de reeds ingevulde gegevens. </p>
                        </Col>

                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap 6. Ontgraven van grond</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                        
                        <p>Als de kwaliteit van de grond bepaald is en de grond is aangemeld bij ROS krijgt u een uniek afvasltroomnummer. Tijd om aan de slag te gaan. Let er wel op! Als u gaat graven in grond dient u een KLIC-melding te doen. Klik hier: <a href="https://www.kadaster.nl/" target="_blank">https://www.kadaster.nl/</a> voor meer informatie.</p>
                        </Col>
                        
                        <hr/>
                        <Col sm={12} className="set-default-margin ">
                            <h4>Stap 7. Transporteren grond</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step last-col">
                        
                        <p>Iedere vracht grond dient voorzien te zijn van een  volledig ingevulde begeleidingsbrief met een uniek afvalstroomnummer. Dit geldt niet voor particulieren met eigen aanhangers. </p>
                        </Col>
                        {this.state.sidenodeDirix ? 
                        <Col sm={4} className="sidenote-block step-three">
                            <p>
                            Op basis van de bodemkwaliteitskaart kan uw grond de volgende klasse hebben: AW2000, Wonen en Industrie. Let op, dit zijn gebruikelijke termen in de branche en hebben niks met uw adres te maken. De kwaliteit van grond kan bepalend zijn voor de stortprijs.   

                            </p>
                           
                            <span className="close-link" onClick={()=>this.setState({sidenodeDirix:false})}>Sluiten</span>
                        </Col>:null}
                        
                        </Row> :null}

                        {this.state.ap04clicked ?   <Row id="step-3">
                        <Col sm={12} className="set-default-margin">
                            <h4>Waar kunt u met de grond naar toe?</h4>
                            </Col>
                            <Col sm={10} className="set-default-margin   full-row-step">

                        <p>Met een AP04 onderzoek kunt u de grond storten bij een grondbank, in een groeve of toepassen op een werk.</p>
                        
                        </Col>
                        
                        
                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap 3. U kunt uw gegevens indienen bij Dirix.</h4>
                            </Col>
                        <Col sm={10} className="set-default-margin outline full-row-step">
                        
                        
                        <p>Uw grond wordt beschouwd als verdachte grond en kan dus enkel op basis van een rapport ingenomen worden. U kunt hiervoor een formulier invullen of digitaal opvragen.</p>
                    
                        </Col>
                    
                        
                        
                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap 4. Uw grond wordt beoordeeld door Dirix.</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                         <span className="side-note" onClick={()=>this.setState({sidenodeDirix:true})}><i class="fas fa-info-circle"></i></span>
                        
                        <p>Op basis van de herkomst van de grond en uw rapport, kunnen wij beoordelen, onder welke kwaliteit uw grond mag worden ingenomen. Houdt er rekening mee dat het beoordelen van sommige rapoorten enkele dagen in beslag kan nemen.</p>
                        </Col>
                        
                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap 5. Aanmelden afvoeren grond</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                        <p></p>
                        
                        <p>Als de kwaliteit van uw grond is bepaald, kunt u de grond aanmelden bij ROS. U kunt hiervoor een formulier invullen of digitaal aanvragen. Wij gebruiken hiervoor de reeds ingevulde gegevens. </p>
                        </Col>

                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap 6. Ontgraven van grond</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step">
                        
                        <p>Als de kwaliteit van uw grond is bepaald, kunt u de grond aanmelden bij ROS. U kunt hiervoor een formulier invullen of digitaal opvragen. Omdat het grond betreft voorzien van een partij keuring, bespreken we met u de diverse mogelijkheden.</p>
                        </Col>
                        
                        <hr/>
                        <Col sm={12} className="set-default-margin">
                            <h4>Stap 7. Transporteren grond</h4>
                            </Col>
                         <Col sm={10} className="set-default-margin outline  full-row-step last-col">
                        
                        <p>Als de kwaliteit en de bestemming van de grond is bepaald, is eht tijd om aan de slag te gaan. Indien de grond naar ROS gaat, ontvangt u van ons een afvalstroomnummer. Indien de grond op een werk wordt toegepast, dienen we dit te melden bij bevoegd gezag. Let op! Deze melding moet minimaal 5 dagen voor aanvang van werkzaamheden uitgevoerd zijn. Let op! als u gaat graven in de grond, dient u een KLIC-melding te doen. <a  target="_blanl" href="https://klic-app.nl/zo-werkt-het/?">Klik hier</a> voor meer informatie over KLIC-meldingen.</p>
                        </Col>
                        {this.state.sidenodeDirix ? 
                        <Col sm={4} className="sidenote-block step-three">
                            <p>
                            Op basis van de bodemkwaliteitskaart kan uw grond de volgende klasse hebben: AW2000, Wonen en Industrie. Let op, dit zijn gebruikelijke termen in de branche en hebben niks met uw adres te maken. De kwaliteit van grond kan bepalend zijn voor de stortprijs.   

                            </p>
                           
                            <span className="close-link" onClick={()=>this.setState({sidenodeDirix:false})}>Sluiten</span>
                        </Col>:null}
                        
                        </Row> :null}

                        {this.state.noResearch ?   <Row id="step-3">
                        <Col sm={12} className="set-default-margin">
                            <h4>Neem contact op!</h4>
                            </Col>
                            <Col sm={10} className="set-default-margin outline full-row-step">

                        <p>Helaas, zonder rapport kan uw grond niet ingenomen worden bij Recycling Overslag Stein. Wij helpen u graag verder met het organiseren van dit rapport. Neem contact met ons op via de volgende link</p>
                        <a className="button-last-step" href="https://dirix.nl/aan-de-slag/" target="_blank">Contact opnemen</a>
                        </Col>
                        
                        <hr/>
                        
                        
                        </Row> :null}
                       


                            
                            
                        </Col>
    }
                    </Row>
                    :null}
                    
                       
                    </Col>
               </Row>
           </Col>
        );
    }
};

export default AfvoerenComponent;