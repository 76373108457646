import React, { Component,useState, useEffect  } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";



class LeftColum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount(){
        console.log('hoogte is:'+window.innerHeight)
    }

    



    render() {
        return (
                <Col  sm={2} className="left-colum" style={{height: window.innerHeight+150}}>
                    <Row>
                        <Col sm={12} className="menu-item">
                        <a href="#afval-storten" onClick={this.props.handler('afval')}>
                        <img src={require('../../../assets/images/svg/materiaal-storten.svg')} />
                            <p>
                                Afval storten
                            </p>
                        </a>
                        </Col>
                        <Col sm={12} className="menu-item">
                        <a href="#afhalen" onClick={this.props.handler('afhalen')}>
                        <img src={require('../../../assets/images/svg/producten-afhalen.svg')} />
                            <p>
                                Producten afhalen
                            </p>
                        </a>
                        </Col>
                        <Col sm={12} className="menu-item">
                        <a href="#grond" onClick={this.props.handler('grond')}>
                        <img src={require('../../../assets/images/svg/grondbank.svg')} />
                            <p>
                                Grondbank
                            </p>
                        </a>
                        </Col>

                    </Row>
                </Col>

  
        );
    }
};

export default LeftColum;