import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class asnNummerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
           <Col sm={12} className="second-home-section" id="asn">
               <Row>
                   <Col sm={6} className="second-home-text-section">
                       <h2>ASN Nummer aanvragen</h2>
                       <img className="hr-line" src={require('../../../assets/images/svg/hr-line.svg')} />
                       <p>
                       Momenteel werken wij aan een systeem voor het aanvragen van afvalstroomnummers. Tot die tijd willen wij u vragen direct contact met ons op te nemen met vragen rondom de afvalstroomnummers.
                       </p>
                       <a className="ros-button-cta" target="_blank" href="https://dirix.nl/aan-de-slag/">
                           Contact opnemen
                       </a>


                   </Col>
                   <Col sm={6} className="second-home-image-section">
                       {/*} <img src={require('../../../assets/images/materialen/beton.jpg')} />*/}
                    </Col>
                    <Col sm={12} className="table">
                       
                    </Col>
               </Row>
           </Col>
        );
    }
};

export default asnNummerComponent;