import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class GrondbankComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step2:false,
            particulier:false,
            niet:false,
            step3:false,
            sidenodeHome:false,
            sidenodeOther:false,
            sidenodeDirix:false,
            vbo:false,
            ap04:false,
            geen:false,
            ap04clicked:false,
            vboclicked:false,

            grond:Boolean
        };
    }



    render() {
        return (
           <Col sm={12} className="second-home-section" id="grond">
               <Row>
                   <Col sm={6} className="second-home-text-section">
                       <h2>GRONDBANK (BRL9335-1)</h2>
                       <img className="hr-line" src={require('../../../assets/images/svg/hr-line.svg')} />
                       <p>
                       Dirix Elsloo heeft op haar locatie Recycling Overslag Stein (ROS) in Stein een Grondbank
                        Hiertoe is de locatie ROS van Dirix gecertificeerd conform BRL9335-1.
                        In deze gecertificeerde  Grondbank kan Dirix op de locatie ROS alle grond accepteren die voldoet aan de volgend eisen:
                        <ul>
                        <li>Grond is onverdacht; </li>
                        <li>Komt de grond vrij uit een tuin of anderszins niet van onder een verharding dan kan de grond worden beoordeeld o.b.v. de bodemkwaliteitskaart;</li>
                        <li>Komt de grond vrij van onder een verharding dan moet er eerst een verkennend bodem (vbo) onderzoek worden uitgevoerd. Aan de hand van dit vbo wordt door Dirix beoordeeld of de grond kan worden geaccepteerd in de Grondbank;</li>
                        <li>Alle grond wordt vooraf gescreend door Dirix;</li>
                        <li>Grond kan worden aangevoerd als deze door Dirix is goedgekeurd.</li>
                        </ul>

                        Wilt u zelf grond afvoeren? Klik op onderstaande link en we helpen u graag verder.

                       </p>
                       <a className="ros-button-cta" href="#afvoeren" onClick={()=>this.handler('afvoeren')}>
                           Afvoeren van grond
                       </a>


                   </Col>
                   <Col sm={6} className="second-home-image-section">
                        <img src={require('../../../assets/images/materialen/grondbank.jpg')} />
                    </Col>

               </Row>
           </Col>
        );
    }
};

export default GrondbankComponent;