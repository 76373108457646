import React, {useCallback, useContext} from "react";
import {withRouter, Redirect} from "react-router";
import Firebase from 'firebase';
import {AuthContext} from "../../Auth";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import LoginImage from '../../assets/images/login-image.png';
import Loader from 'react-loader-spinner'
import toast, { Toaster } from 'react-hot-toast';

const Login = ({history}) =>{

    const handleLogin = useCallback(
         event =>{
            event.preventDefault();
            const {email,password} = event.target.elements;
            try{
                 Firebase.auth().signInWithEmailAndPassword(email.value, password.value);
                history.push("/");
            }catch (error){
                toast.error('Probeer het opnieuw!')
                alert(error);
            }
        },
        [history]
    );

    const { currentUser} = useContext(AuthContext);

    if (currentUser){
        return <Redirect to="/" />;
    }
    
    return (
      <Row >
   <Toaster/>
      <Col lg="6" className="login-col">
      <img src={require("../../assets/images/logo.svg")}  className="logo-login"/>
          <h3>Beter bij ons</h3>
          {/**<h4>Q-sort</h4> */}
          <p>Met behulp van Beter bij ons beheer je jouw eigen plek in de online winkelstraat van Nederland</p>
          <form onSubmit={handleLogin}>
          <Row >

          <input style={{padding: 10, marginTop: 10,}} name="email" type="email" placeholder="Gebruikersnaam" />
        
          </Row>
          <Row >

              <input style={{padding: 10, marginTop: 10,}} name="password" type="password" placeholder="Wachtwoord" />
  
          </Row>
          <button type="submit">Inloggen</button>
           {/** <div className="loading-dots">
            <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} timeout={10000}  />
    </div>*/}
      </form>
      </Col>

      <Col lg="6" className="login-image-bg"></Col>
    </Row>
    );
};


export default withRouter(Login);