import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class ProductenAfhalenComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
           <Col sm={12} className="second-home-section" id="afhalen">
               <Row>
                   <Col sm={6} className="second-home-text-section">
                       <h2>PRODUCTEN AFHALEN</h2>
                       <img className="hr-line" src={require('../../../assets/images/svg/hr-line.svg')} />
                       <p>
                       Bij Dirix Elsloo: locatie Recycling Overslag Stein, kunt u terecht voor diverse (gecertificeerde) grondstoffen. Zowel zakelijke als particuliere klanten zijn welkom. Op locatie is vrijwel altijd een laadschop aanwezig die het gewenste product voor u kan laden.  Ondanks het feit dat we de bevoorrading zelf verzorgen, kan het zijn dat uw product niet beschikbaar is. Neem eventueel voor de zekerheid even contact met ons op. Onze medewerkers staan u graag te woord.  
                       </p>
                       <a className="ros-button-cta" target="_blank" href="https://www.dirix.nl/aan-de-slag/#Contact-opnemen">
                           Tarieven opvragen
                       </a>


                   </Col>
                   <Col sm={6} className="second-home-image-section">
                        <img src={require('../../../assets/images/materialen/afhalen.jpg')} />
                    </Col>
                    <Col sm={12} className="table">
                    <table>
                            <tr>
                                <th>Af te halen producten</th>
                                <th>ROS</th>
                                <th>Tuin & Bouw</th>
                            </tr>
                            <tr>
                                <td>Betongranulaat 0/20 </td>
                                <td>(op aanvraag)</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Betongranulaat 0/31,5</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Brekerzand</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Cunetzand</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Mengranulaat 0/16</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Mengranulaat 0/20</td>
                                <td>(op aanvraag)</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Mengranulaat 0/31,5</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Metselzand</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Ophoogzand</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Straatzand</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Teelaarde</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>

                            <tr>
                                <td>Vloerzand 0/4</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>

                            <tr>
                                <td>Vulzand</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Bouwmaterialen</td>
                                <td>X</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Sierbestrating</td>
                                <td>X</td>
                                <td>✓</td>
                            </tr>
                        </table>
                       
                    </Col>
               </Row>
           </Col>
        );
    }
};

export default ProductenAfhalenComponent;