import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';
import { ThreeDRotationSharp } from '@material-ui/icons';

class AddProductsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newProductKey:'',
            productName:'',
            productDesc:'',
            btwValue:'',
            price:0,
            status:'selected',
            image:[],
            images:[],
            uploading:false,
            uploaded:false,
            url:'',
            imageAvailable: false,
            activeVariations: false,
            cat:'selected',
            categories:[],
            catActive:false,
            tag:'selected',
            tags:[],
            tagActive:false,
            stock:0,
            clientUUID:'-ohlnfwebk',
            city:'',
            error:false,
            errorMessage:'Controller',
            variant:'selected',
            color:false,
            weight:false,
            quantity:false,
            measure:false,
            addVariantText:'',
            addVariantPrice:'',
            addVariantStock:0,
            variations:[],
            changeVariantText:'',
            changeVariantPrice:'',
            changeVariantStock:0,
            
        };
    }

    getEmployees(){
    
        /*let ref = Firebase.database().ref('/clients/-uhfwoebfwioudb/content/services');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
                let employee = {resourceTitle: state.name, resourceId: state.resourceId};
                this.setState(prevState => ({
                    resourceMap: [...prevState.resourceMap, employee]
                }))  

        })});*/
    }


    addProduct(){
        if(this.state.productName != '' ){
        var key = Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/').push().key;
            Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+key).set({
                id: key,
                btwValue: this.state.btwValue,
                name: this.state.productName,
                price: Number(this.state.price),
                productDesc: this.state.productDesc,
                status: this.state.status,
                stock: Number(this.state.stock),
                activeVariations: this.state.activeVariations,
                variations: this.state.variations,
                variant: this.state.activeVariations ?this.state.variations : 'no-variation'


        
            }).then((data)=>{

                for(let i = 0; i< this.state.categories.length; i++){
                    var keyCat = Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+key+'/categories').push().key;
                    Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+key+'/categories/'+keyCat).set({
                   
                        id: keyCat,
                        name: this.state.categories[i]
                    
                
                    }).then((data)=>{
                    
            
                    }).catch((error)=>{
                        //error callback
                        console.log('error ' , error)
                    })
                }
                for(let m = 0; m< this.state.images.length; m++){
                    var keyImg= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+key+'/images').push().key;
                    Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+key+'/images/'+keyImg).set({
                   
                        id: keyImg,
                        url: this.state.images[m]
                    
                
                    }).then((data)=>{
                    
            
                    }).catch((error)=>{
                        //error callback
                        console.log('error ' , error)
                    })
                }

                for(let t = 0; t< this.state.tags.length; t++){
                    var keyTag = Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+key+'/tags').push().key;
                    Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+key+'/tags/'+keyTag).set({
                  
                        id: keyTag,
                        name: this.state.tags[t]
                    
                
                    }).then((data)=>{

                    
            
                    }).catch((error)=>{
                        //error callback
                        console.log('error ' , error)
                    })
                }

                toast.success('Product toegevoegd');
               
    
            }).catch((error)=>{
                //error callback
                console.log('error ' , error)
            })
        }else{
            this.setState({
                error: true,
                errorMessage:'Controlleer de productnaam.'
            })
        }
    }



    componentDidMount() {
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid
        })
        let ref = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid);
        ref.on('value' , snapshot => {
           
   
            let state = snapshot.val();
                this.setState({
                    city: state.city
                }) 
            
        });
        this.getEmployees();
        this.setState({
            newProductKey:Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/').push().key
        })
    }

    handleaVariations(event){
console.log(event)
        if(this.state.activeVariations === true){
            this.setState({
                activeVariations: false
            })
        }else if(this.state.activeVariations === false){
            this.setState({
                activeVariations: true
            })
        }
        
    }

    handleChange(e){
        console.log(e.target.files[0])
        this.setState({
          image: e.target.files[0],
          errorMessageLogo:''
        })
      }

      handleUpload(){
          const uniqueId =this.makeid(12);
        const {image} = this.state;
        this.setState({
          uploaded:true
        })

        console.log(image)

        if(image != null){
            const uploadTask = Firebase.storage().ref(`images/${Firebase.auth().currentUser.uid}/${uniqueId}${image.name}`).put(image);
            uploadTask.on(
              'state_changed',
              snapshot => {
              },
              error => {
                console.log(error);
              },
              () => {
                Firebase.storage()
                  .ref('images/'+Firebase.auth().currentUser.uid)
                  .child(uniqueId+image.name)
                  .getDownloadURL()
                  .then(url => {
                    toast.success('Afbeelding opgeslagen');
                      
                    this.setState(prevState =>({
                      images: [...prevState.images,url],
                      imageAvailable:true,
                      uploading: true
                    }))
                  });
              }
            );
          }else{
          this.setState({
            errorMessageLogo: 'Er is geen foto geselecteert.',
            uploaded: false,
            uploading: false
          })
          
        }
    
        
      }

      makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

      addCat(){
          this.setState(prevState => ({
            categories: [...prevState.categories, this.state.cat],
            catActive: true,
          }))
      }

      addTag(){
        this.setState(prevState => ({
          tags: [...prevState.tags, this.state.tag],
          tagActive: true,
        }))
    }

    changeVariant(variant){
        console.log(variant);
        if(variant === 'Kleur'){
            this.setState({
                variant:variant,
                color:true,
                weight:false,
                quantity:false,
                measure:false
            })
        }else if(variant === 'Gewicht'){
            this.setState({
                variant:variant,
                color:false,
                weight:true,
                quantity:false,
                measure:false
            })
        }else if(variant === 'Hoeveelheid'){
            this.setState({
                variant:variant,
                color:false,
                weight:false,
                quantity:true,
                measure:false
            })
        }else if(variant === 'Maat'){
            this.setState({
                variant:variant,
                color:false,
                weight:false,
                quantity:false,
                measure:true
            })
        }
    }

    addVariation(){
        if(this.state.addVariantText !='' && this.state.addVariantPrice !='' && this.state.addVariantPrice !=0){
            let newVariation = {id: this.state.variations.length+1, name: this.state.addVariantText, price: this.state.addVariantPrice, stock:this.state.addVariantStock}
            this.setState(prevState => ({
                variations: [...prevState.variations, newVariation],
                addVariantText:'',
                addVariantPrice:'',
                addVariantStock:0,
            }))
        }else{
            toast.error('Niet alle velden zijn gevult probeer het opnieuw');
            this.setState({
                addVariantText:'',
                addVariantPrice:'',
                addVariantStock:0,
            })
        }

    }

  changeVariantProduct= async (item) =>{
      if(this.state.color === true){
       await this.setState({
            variations: this.state.variations.filter((object) => object.id !== item.id)
        })
       

                let changedVariant = {id: item.id,type:'Kleur', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
             await   this.setState(prevState => ({
                    variations: [...prevState.variations, changedVariant],
                    changeVariantText:'',
                    changeVariantPrice:'',
                    changeVariantStock:0,
                }))
  
            }else  if(this.state.weight === true){
                await this.setState({
                    variations: this.state.variations.filter((object) => object.id !== item.id)
                })
                
               
        
                        let changedVariant = {id: item.id,type:'Gewicht', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
                     await   this.setState(prevState => ({
                            variations: [...prevState.variations, changedVariant],
                            changeVariantText:'',
                            changeVariantPrice:'',
                            changeVariantStock:0,
                        }))
          

            }

           else if(this.state.quantity === true){
                await this.setState({
                    variations: this.state.variations.filter((object) => object.id !== item.id)
                })
               
        
                        let changedVariant = {id: item.id,type:'Hoeveelheid', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
                     await   this.setState(prevState => ({
                            variations: [...prevState.variations, changedVariant],
                            changeVariantText:'',
                            changeVariantPrice:'',
                            changeVariantStock:0,
                        }))
          

            }

            else if(this.state.measure === true){
                await this.setState({
                    variations: this.state.variations.filter((object) => object.id !== item.id)
                })
               
        
                        let changedVariant = {id: item.id,type:'Maat', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
                     await   this.setState(prevState => ({
                            variations: [...prevState.variations, changedVariant],
                            changeVariantText:'',
                            changeVariantPrice:'',
                            changeVariantStock:0,
                        }))
          

            }

            this.setState({
                changeVariantText:'',
                changeVariantPrice:'',
                changeVariantStock:0,
            })

    }

    deleteImage(url){
        this.setState({
            images: this.state.images.filter((item)=> item !== url)
        })
    }


    

    render() {
        return (
            <div>
                <Toaster/>
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                    <Link to={{pathname: `/producten`}}>Terug</Link>
                    
                    <Button className="main-save-button" onClick={()=>this.addProduct()}>Opslaan</Button>
                   {this.state.error ? <span className="error-message-product">{this.state.errorMessage}</span>:null}
                    {/*<Link className="align-button-right">Product toevoegen</Link>*/}
                </Col>
                <Col sm={8} className="colum-left">
                    <Row>
                        <Col sm={12} className="add-product-colum">
                            <label>Productnaam</label><br/>
                            <input type="text" value="" value={this.state.productName} onChange={(event)=>this.setState({productName: event.target.value, error:false})}/><br/><br/>
                            <label>Product omschrijving</label><br/>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.productDesc}
                                
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    this.setState({productDesc: data})
                                } }

                            />
                        </Col>
                        <Col sm={12} className="add-product-colum">
                            <label>Media</label><br/>
                            <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleChange(event)} /><Button className="add-button-settings" onClick={()=>this.handleUpload()}>Voeg afbeelding toe</Button> <br/><br/>
                            <label>Geselecteerde product foto's</label>
                            {this.state.imageAvailable ?
                             <Row>
                                 {this.state.images.map((image)=>(
                                <Col sm={4} className="product-images">
                                <span className="delete-image button-list button-onimage" >
                                    <Button onClick={()=>this.deleteImage(image)}>Verwijder</Button>
                                </span>
                                <img src={image}/>
                            </Col>
                                ))}
                            </Row>
                            :
                            <Row>
                                <Col sm={12} className="nothing-present">
                                    <span>Geen afbeeldingen geselecteert</span>
                                </Col>
                            </Row>
                            }
                           
                        </Col>
                        {this.state.activeVariations ?null:<Col sm={12} className="add-product-colum">
                            <label>Prijs per stuk incl. BTW</label><br/>
                            <span className="input-price">€ <input type="text" value={this.state.price} onChange={(event)=>this.setState({price: event.target.value})}/> </span><br/><br/>
                            <label>Voorraad</label><br/>
                            <input type="number" value={this.state.stok} onChange={(event)=>this.setState({stock: event.target.value})}/><br/><br/>
                            <label>BTW-percentage</label><br/>
                            <select value={this.state.btwValue} onChange={(event)=>this.setState({btwValue: event.target.value})}>
                            <option selected="selected" disabled="disabled">Selecteer een BTW percentage</option>
                                <option value="0">0%</option>
                                <option value="6">6%</option>
                                <option value="9">9%</option>
                                <option value="21">21%</option>
                            </select>
                        </Col>}
                        <Col sm={12} className="add-product-colum">
                            <label>Varianten</label><br/>
                            <input type="checkbox" checked={this.state.activeVariations} onChange={(event)=> this.handleaVariations(event.target.value)}/><label>Dit product heeft meerdere mogelijkheden, zoals verschillende maten of kleuren</label>
                           
                         {this.state.activeVariations ?   
                         <div><hr/>
                         <label>Type variant</label><br/>
                          <select value={this.state.variant} onChange={(event)=> this.changeVariant(event.target.value)}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een variant</option>
                                <option value="Kleur">Kleur</option>
                                <option value="Gewicht">Gewicht</option>
                                <option value="Hoeveelheid">Hoeveelheid</option>
                                <option value="Maat">Maat</option>
                            </select>
                            <label>BTW-percentage</label><br/>
                            <select value={this.state.btwValue} onChange={(event)=>this.setState({btwValue: event.target.value})}>
                            <option selected="selected" disabled="disabled">Selecteer een BTW percentage</option>
                                <option value="0">0%</option>
                                <option value="6">6%</option>
                                <option value="9">9%</option>
                                <option value="21">21%</option>
                            </select>
                            <label>Opties</label><br/>
                            {this.state.variations.sort((a, b) => (a.id > b.id) ? 1 : -1).map((item)=>(
                            <Row className="variations-row">
                                <Col sm={3}>
                                    <label>Optie</label>
                                    <input type="text" placeholder={item.name} onChange={(event)=> this.setState({changeVariantText: event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Prijs per stuk</label>
                                    <input type="text" placeholder={item.price}  onChange={(event)=> this.setState({changeVariantPrice: event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Voorraad</label>
                                    <input type="number" placeholder={item.stock}  onChange={(event)=> this.setState({changeVariantStock: event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <Button className="add-variation" onClick={()=>this.changeVariantProduct(item)}>Wijzig variant</Button>
                                </Col>
                            </Row>
                            ))}

                            {this.state.color ?
                            <Row className="variations-row">
                                <Col sm={3}>
                                    <label>Kleur</label>
                                    <input type="text" value={this.state.addVariantText} onChange={(event)=>this.setState({addVariantText:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Prijs per stuk</label>
                                    <input type="text" value={this.state.addVariantPrice} onChange={(event)=>this.setState({addVariantPrice:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Voorraad</label>
                                    <input type="number" value={this.state.addVariantStock} onChange={(event)=>this.setState({addVariantStock:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <Button className="add-variation" onClick={()=>this.addVariation()}>Voeg variant toe</Button>
                                </Col>
                            </Row>
                            :null}
                             {this.state.weight ?
                            <Row className="variations-row">
                                <Col sm={3}>
                                    <label>Gewicht</label>
                                    <input type="text" value={this.state.addVariantText} onChange={(event)=>this.setState({addVariantText:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Prijs per stuk</label>
                                    <input type="text" value={this.state.addVariantPrice} onChange={(event)=>this.setState({addVariantPrice:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Voorraad</label>
                                    <input type="number" value={this.state.addVariantStock} onChange={(event)=>this.setState({addVariantStock:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <Button className="add-variation" onClick={()=>this.addVariation()}>Voeg variant toe</Button>
                                </Col>
                            </Row>
                            :null}
                            {this.state.quantity ?
                            <Row className="variations-row">
                                <Col sm={3}>
                                    <label>Hoeveelheid</label>
                                    <input type="text" value={this.state.addVariantText} onChange={(event)=>this.setState({addVariantText:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Prijs per stuk</label>
                                    <input type="text" value={this.state.addVariantPrice} onChange={(event)=>this.setState({addVariantPrice:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Voorraad</label>
                                    <input type="number" value={this.state.addVariantStock} onChange={(event)=>this.setState({addVariantStock:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <Button className="add-variation" onClick={()=>this.addVariation()}>Voeg variant toe</Button>
                                </Col>
                            </Row>
                            :null}
                             {this.state.measure ?
                            <Row className="variations-row">
                                <Col sm={3}>
                                    <label>Maat</label>
                                    <input type="text" value={this.state.addVariantText} onChange={(event)=>this.setState({addVariantText:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Prijs per stuk</label>
                                    <input type="text" value={this.state.addVariantPrice} onChange={(event)=>this.setState({addVariantPrice:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Voorraad</label>
                                    <input type="number" value={this.state.addVariantStock} onChange={(event)=>this.setState({addVariantStock:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <Button className="add-variation" onClick={()=>this.addVariation()}>Voeg variant toe</Button>
                                </Col>
                            </Row>
                            :null}

                            </div>:null}
                            
                        </Col>
                        <Col sm={12} className="last-col add-product-colum">
                        < hr/>
                        <Button onClick={()=>this.addProduct()}>Opslaan</Button>
                        </Col>

                    </Row>
                </Col>
            
                <Col sm={4}>
                    <Row>
                        <Col sm={12} className="add-product-colum right-product-add-colom">
                            <label>Productstatus</label><br/>
                            <select value={this.state.status} onChange={(event)=>this.setState({status: event.target.value})}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een status</option>
                                <option value="concept">Concept</option>
                                <option value="actief">Actief</option>
                            </select>

                        </Col>
                        <Col sm={12} className="add-product-colum right-product-add-colom categories-products">
                        <label>Categorieën</label><br/>
                            <select value={this.state.cat} onChange={(event)=>this.setState({cat: event.target.value})}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een categorie</option>
                                <option value="Kleding, Schoenen & Lederwaren">Kleding, Schoenen & Lederwaren</option>
                                <option value="Internet, Tv & Bellen">Internet, Tv & Bellen</option>
                                <option value="Wonen & Inrichten">Wonen & Inrichten</option>
                                <option value="Auto's, Brommers & Fietsen">Auto's, Brommers & Fietsen</option>
                                <option value="Flora & Fauna">Flora & Fauna</option>
                                <option value="Uiterlijke Verzorging">Uiterlijke Verzorging</option>
                                <option value="Zorg & Welzijn">Zorg & Welzijn</option>
                                <option value="Sieraden & Accessoires">Sieraden & Accessoires</option>
                                <option value="Sport, Muziek & Vrije tijd">Sport, Muziek & Vrije tijd</option>
                                <option value="Eten & Drinken">Eten & Drinken</option>
                                <option value="Warenhuis & Speelgoed">Warenhuis & Speelgoed</option>
                            </select><br/>
                            <Button onClick={()=>this.addCat()}>Maak categorie aan</Button><br/><br/>
                            <br/>

                            <label>Geselecteerde categorien</label><br/>
                            {this.state.catActive?
                            <ul>
                                {this.state.categories.map((item)=>(
                                    <li>{item}</li>
                                ))}
                               
                                
                            </ul>
                            :<span>Geen categorien geselecteert</span>}
                            
                        </Col>
                        {/*<Col sm={12} className="add-product-colum right-product-add-colom categories-products">
                            <label>Tags</label><br/>
                            <select value={this.state.tag} onChange={(event)=>this.setState({tag: event.target.value})}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een tag</option>
                                <option value="Koffie">Koffie</option>
                                <option value="Thee">Thee</option>
                                <option value="Frisdrank">Frisdrank</option>
                                <option value="Warm">Warme dranken</option>
                                <option value="Koud">Koude maaltijd</option>
                                <option value="Warme">Warme Maaltijd</option>
                                <option value="Dames Kleding">Dames kleding</option>
                                <option value="Heren Kleding">Heren kleding</option>
                                <option value="Kinder Kleding">Kinder kleding</option>
                                <option value="Heren Kleding">Heren Sieraden</option>
                                <option value="Dames Kleding">Dames Sieraden</option>
                                <option value="Kinder Kleding">Kinder kleding</option>
                               
                            </select><br/>
                            <Button onClick={()=>this.addTag()}>Maak tags aan</Button><br/><br/>
                            <br/>

                            <label>Geselecteerde tags</label><br/>
                            {this.state.tagActive?
                            <ul>
                                {this.state.tags.map((item)=>(
                                    <li>{item}</li>
                                ))}
                               
                                
                            </ul>
                            :<span>Geen tags geselecteert</span>}
                            
                                </Col>*/}
                    </Row>
                </Col>
   
            </Row>
            </div>



        );
    }
};

export default AddProductsComponent;