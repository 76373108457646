import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class opoverslagComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
           <Col sm={12} className="second-home-section" id="overslag">
               <Row>
                   <Col sm={6} className="second-home-text-section">
                       <h2>OP- EN OVERSLAG</h2>
                       <img className="hr-line" src={require('../../../assets/images/svg/hr-line.svg')} />
                       <p>
                       Dirix beschikt over een vergunde locatie van ca. 4 hectare, oftewel Recycling Overslag Stein. Deze locatie kan Dirix diverse afvalstoffen accepteren voor tijdelijke op- en overslag of eindverwerking. Indien u wilt weten of uw afvalstof geaccepteerd wordt, bekijken dan <a href="#afval-storten" onClick={()=>this.handler('afval')}>hier</a> de lijst. Voor tarieven kunt u contact met ons opnemen.  
                       </p>
                       <a className="ros-button-cta" target="_blank" href="https://www.dirix.nl/aan-de-slag/#Contact-opnemen">
                           Contact opnemen
                       </a>


                   </Col>
                   <Col sm={6} className="second-home-image-section">
                        <img src={require('../../../assets/images/materialen/overslag.jpg')} />
                    </Col>
                    <Col sm={12} className="table">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ZiwoI9iTGBo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
                       
                    </Col>
               </Row>
           </Col>
        );
    }
};

export default opoverslagComponent;