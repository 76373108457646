import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import HomeComponent from "../../components/frontend/HomeComponent";


const HomeView = () => (
  <Container fluid >
    <HomeComponent />
    
  </Container>
);

export default HomeView;
