import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class MateriaalStortenHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
           <Col sm={12} className="second-home-section"  id="afval-storten" >
               <Row>
                   <Col sm={6} className="second-home-text-section">
                       <h2>AFVAL STORTEN</h2>
                       <img className="hr-line" src={require('../../../assets/images/svg/hr-line.svg')} />
                       <p>
                       Sinds 2014 is Dirix Elsloo in het bezit van het voormalige Meuleberg terrein. Het terrein is opgekocht en heeft de naam Recycling Overslag Stein (ROS) gekregen. Zowel particulieren als bedrijven komen hier dagelijks hun afval storten. Een aantal afvalstoffen worden verwerkt tot gecertificeerd bouwproduct. Andere afvalstoffen worden in grote hoeveelheden doorgevoerd naar afvalverwerkers in het land. 
                       </p>
                       <a className="ros-button-cta" target="_blank" href="https://www.dirix.nl/aan-de-slag/#Contact-opnemen">
                           Tarieven opvragen
                       </a>


                   </Col>
                   <Col sm={6} className="second-home-image-section">
                        <img src={require('../../../assets/images/materialen/dummy-image.jpg')} />
                    </Col>
                    <Col sm={12} className="table">
                    <span className="annoucment">* Grond, funderingsmateriaal en teervrij asfalt dient voorzien te zijn van een rechtsgeldig analyserapport en/of met schriftelijk goedkeuring van Dirix Elsloo BV.</span>
                    <table>
                            <tr>
                                <th>Te storten producten</th>
                                <th>ROS</th>
                                <th>Tuin & Bouw</th>
                            </tr>
                            <tr>
                                <td>Puin (minder dan 50x50x30cm)</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Puin met grond*</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Puin (grof meer dan 50x50x30cm)</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Puin (enkelasser)</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Puin (dubbelasser)</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Grond*</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Grond* met stenen</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Funderingsmateriaal*</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Asfalt teervrij*</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Asfalt teerhoudend</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>Hout A</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Hout B</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Hout C</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>BSA</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Groenafval</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Gips</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Gasbeton</td>
                                <td>✓</td>
                                <td>✓</td>
                            </tr>
                            <tr>
                                <td>Asbest</td>
                                <td>✓</td>
                                <td>X</td>
                            </tr>
                        </table>
                       


                       
                    </Col>
               </Row>
           </Col>
        );
    }
};

export default MateriaalStortenHome;