import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class SubFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
         <Col sm={12} className="sub-footer">
                <h6>Copyright at ROS Stein | Deze website is ontworpen & ontwikkeld door <b>PixelPro’s</b></h6>

        </Col>

        );
    }
};

export default SubFooter;