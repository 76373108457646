import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast('Je wijzigingen zijn opgeslagen!');

class ChangeProductsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            productDesc:'',
            image:[],
            images:[],
            imagesNew:[],
            catsNew:[],
            tagsNew:[],
            uploading:false,
            uploaded:false,
            url:'',
            imageAvailable: false,
            activeVariations: false,
            cat:'',
            categories:[],
            catActive:false,
            tag:'',
            tags:[],
            tagActive:false,
            clientUUID:'-ohlnfwebk',
            name:'',
            city:'',
            stock:0,
            variant:'selected',
            color:false,
            weight:false,
            quantity:false,
            measure:false,
            addVariantText:'',
            addVariantPrice:'',
            addVariantStock:0,
            variations:[],
            changeVariantText:'',
            changeVariantPrice:'',
            changeVariantStock:0,
        };
    }

    getEmployees(){
    
        let ref = Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id);
        ref.on('value' , snapshot => {
            this.state.products =[];
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            console.log("resources:"+state);
          
                this.setState(prevState => ({
                    products: [...prevState.products, state]
                }))  

                console.log(state.categories)

                let cats = Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+state.id+'/categories');
                cats.on('value' , snapshot => {
            
                        snapshot.forEach((childSnap) => {
                        let stateFac = childSnap.val();
                        
                        let newCat = {catId:state.id, id:stateFac.id,  name:stateFac.name}
                            this.setState(prevState => ({
                                categories: [...prevState.categories, newCat],
                            }));
                    });
                    
                })


               
            

               

                

        })
    });
    }

    changeProduct(){

      
        console.log('dpat')

        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id).update({
            name: this.state.name,
            productDesc:this.state.productDesc,
            price:Number(this.state.price),
            stock:Number(this.state.stock),
            status:this.state.status,
            btwValue:this.state.btwValue,
            activeVariations: this.state.activeVariations,
            variations: this.state.activeVariations ?this.state.variations : 'no-variation',
            variant: this.state.variant
    
        }).then((data)=>{

            for(let m = 0; m< this.state.imagesNew.length; m++){
                var keyImg= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/images').push().key;
                Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/images/'+keyImg).set({
               
                    id: keyImg,
                    url: this.state.imagesNew[m].url
                
            
                }).then((data)=>{
                    this.setState({
                        imagesNew:[]
                    })
                
        
                }).catch((error)=>{
                    //error callback
                    console.log('error ' , error)
                })
            }
            for(let c = 0; c< this.state.catsNew.length; c++){
                var keyCat= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/categories').push().key;
                Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/categories/'+keyCat).set({
               
                    id: keyCat,
                    name: this.state.catsNew[c].name
                
            
                }).then((data)=>{
                    this.setState({
                        catsNew:[]
                    })
                
        
                }).catch((error)=>{
                    //error callback
                    console.log('error ' , error)
                })
            }
            for(let t = 0; t< this.state.tagsNew.length; t++){
                var keyTag= Firebase.database().ref().child('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/tags').push().key;
                Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/tags/'+keyTag).set({
               
                    id: keyTag,
                    name: this.state.tagsNew[t].name
                
            
                }).then((data)=>{
                    this.setState({
                        tagsNew:[]
                    })
                
        
                }).catch((error)=>{
                    //error callback
                    console.log('error ' , error)
                })
            }

           

        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })

        toast.success('Wijzigingen opgeslagen')

    }



    componentWillMount() {
        this.setState({
            clientUUID: Firebase.auth().currentUser.uid
        })

        let ref = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid);
        ref.on('value' , snapshot => {
           
   
            let state = snapshot.val();
                this.setState({
                    city: state.city
                }) 
            
        });
       //this.getEmployees();

console.log(this.props.data.data.productDesc)

        this.setState({
            name:this.props.data.data.name,
            id:this.props.data.data.id,
            productDesc:this.props.data.data.productDesc,
            price:this.props.data.data.price,
            stock:this.props.data.data.stock,
            status:this.props.data.data.status,
            btwValue:this.props.data.data.btwValue,
            activeVariations: this.props.data.data.activeVariations,
            variations: this.props.data.data.activeVariations ?this.props.data.data.variations :[],
            variant: this.props.data.data.activeVariations?this.props.data.data.variant:'selected',

        })

        if(this.props.data.data.variant ==='Kleur'){
            this.setState({
                color:true,
                weight:false,
                quantity:false,
                measure:false,
            })
        }else if(this.props.data.data.variant ==='Maat'){
            this.setState({
                color:false,
                weight:false,
                quantity:false,
                measure:true,
            })
        }
        else if(this.props.data.data.variant ==='Gewicht'){
            this.setState({
                color:false,
                weight:true,
                quantity:false,
                measure:false,
            })
        }
        else if(this.props.data.data.variant ==='Hoeveelheid'){
            this.setState({
                color:false,
                weight:false,
                quantity:true,
                measure:false,
            })
        }

        let images = Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/images');
        images.on('value', snapshot => {
            this.setState({images:[]})
              snapshot.forEach((childSnap)=>{
                let state = childSnap.val();
                console.log(state)
                  //var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user, match: state.match, x: state.x, y: state.y, public: state.public, notes:state.notes, participants: state.participants, bgcolor: state.bgcolor, category: state.category, selectedkeycat: state.selectedkeycat, sprint: parseFloat(state.sprint), zIndex: state.zIndex, isArchived: state.isArchived };
                  this.setState(prevState => ({
                      images: [...prevState.images, state],
                      imageAvailable:true
  
                  } ));
              })
          })

          let categories = Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/categories');
          categories.on('value', snapshot => {
            this.setState({categories:[]})
              snapshot.forEach((childSnap)=>{
                let state = childSnap.val();
                console.log(state)
                  //var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user, match: state.match, x: state.x, y: state.y, public: state.public, notes:state.notes, participants: state.participants, bgcolor: state.bgcolor, category: state.category, selectedkeycat: state.selectedkeycat, sprint: parseFloat(state.sprint), zIndex: state.zIndex, isArchived: state.isArchived };
                  this.setState(prevState => ({
                      categories: [...prevState.categories, state],
                      catActive:true
  
                  } ));
              })
          })
          let tags = Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/tags');
          tags.on('value', snapshot => {
            this.setState({tags:[]})
              snapshot.forEach((childSnap)=>{
                let state = childSnap.val();
                console.log(state)
                  //var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user, match: state.match, x: state.x, y: state.y, public: state.public, notes:state.notes, participants: state.participants, bgcolor: state.bgcolor, category: state.category, selectedkeycat: state.selectedkeycat, sprint: parseFloat(state.sprint), zIndex: state.zIndex, isArchived: state.isArchived };
                  this.setState(prevState => ({
                      tags: [...prevState.tags, state],
                      tagActive:true
  
                  } ));
              })
          })

          
            let variations = Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/variations');
            variations.on('value' , snapshot => {
        
                    snapshot.forEach((childSnap) => {
                    let state = childSnap.val();
                    console.log(state)
                    
                    let newVar = {id:state.id, name:state.id,  price:state.name, stock: state.stock, type: state.type}
                        this.setState(prevState => ({
                            variations: [...prevState.variations, newVar],
                        }));

                  
                });
                
            })
       
    }

    handleaVariations(event){
console.log(event)
        if(this.state.activeVariations === true){
            this.setState({
                activeVariations: false
            })
        }else if(this.state.activeVariations === false){
            this.setState({
                activeVariations: true
            })
        }
        
    }

    handleChange(e){
        this.setState({
          image: e.target.files[0],
          errorMessageLogo:''
        })
      }

      handleUpload(){
        const uniqueId =this.makeid(12);
      const {image} = this.state;
      this.setState({
        uploaded:true
      })

      

      console.log(image)

      toast.loading('Afbeelding wordt verwerkt', {
        duration: 2000,
      })
      

      if(image != null){
          const uploadTask = Firebase.storage().ref(`images/${Firebase.auth().currentUser.uid}/${uniqueId}${image.name}`).put(image);
          uploadTask.on(
              
            'state_changed',
            snapshot => {
            },
            error => {
              console.log(error);
            },
            () => {
                
              Firebase.storage()
                .ref('images/'+Firebase.auth().currentUser.uid)
                .child(uniqueId+image.name)
                .getDownloadURL()
                .then(url => {
                    console.log(url)
                    let newImage = {id: this.state.images.length+'rer', url: url}
                  this.setState(prevState =>({
                    images: [...prevState.images,newImage],
                    imagesNew: [...prevState.imagesNew,newImage],
                    imageAvailable:true,
                    uploading: true,
                    image:[]
                  }))
                });
            }
          );
        }else{
        this.setState({
          errorMessageLogo: 'Er is geen foto geselecteert.',
          uploaded: false,
          uploading: false
        })
        
      }
  
      
    }

    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
   }

      addCat(){
        let newCat = {id:(this.state.categories.length+1)+'fbew', name:this.state.cat}
          this.setState(prevState => ({
            categories: [...prevState.categories, newCat],
            catsNew: [...prevState.catsNew, newCat],
            cat:'',
            catActive: true,
          }))
      }

      addTag(){
          let newTag = {id:(this.state.tags.length+1)+'fbew', name:this.state.tag}
        this.setState(prevState => ({
          tags: [...prevState.tags, newTag],
          tagsNew: [...prevState.tagsNew, newTag],
          tag:'',
          tagActive: true,
        }))
        
    }

    deleteImage(image){
        console.log(image)
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/images/'+image).remove();

        if(this.state.images.length < 1){
            this.setState({
                imageAvailable:false
            })
        }

        toast.error('Afbeelding verwijderd')
    }

    deleteCat(cat){
      
        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/categories/'+cat).remove();

        if(this.state.categories.length < 1){
            this.setState({
                catActive:false
            })
        }

        toast.error('Categorie verwijderd')
    }

    deleteTag(tag){

        Firebase.database().ref('/cities/'+this.state.city+'/shops/'+Firebase.auth().currentUser.uid+'/products/'+this.props.data.data.id+'/tags/'+tag).remove();

        if(this.state.tags.length < 1){
            this.setState({
                tagActive:false
            })
        }

        toast.error('Tag verwijderd')
    }

    addVariation(){
        if(this.state.addVariantText !='' && this.state.addVariantPrice !='' && this.state.addVariantPrice !=0){
            let newVariation = {id: this.state.variations.length+1, name: this.state.addVariantText, price: this.state.addVariantPrice, stock:this.state.addVariantStock}
            this.setState(prevState => ({
                variations: [...prevState.variations, newVariation],
                addVariantText:'',
                addVariantPrice:'',
                addVariantStock:0,
            }))
        }else{
            toast.error('Niet alle velden zijn gevult probeer het opnieuw');
            this.setState({
                addVariantText:'',
                addVariantPrice:'',
                addVariantStock:0,
            })
        }

    }

  changeVariantProduct= async (item) =>{
      console.log(item)
      console.log(this.state.color)

      if(this.state.color === true){
       await this.setState({
            variations: this.state.variations.filter((object) => object.id !== item.id)
        })
       

                let changedVariant = {id: item.id,type:'Kleur', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
             await   this.setState(prevState => ({
                    variations: [...prevState.variations, changedVariant],
                    changeVariantText:'',
                    changeVariantPrice:'',
                    changeVariantStock:0,
                }))
  
            }else  if(this.state.weight === true){
                await this.setState({
                    variations: this.state.variations.filter((object) => object.id !== item.id)
                })
                
               
        
                        let changedVariant = {id: item.id,type:'Gewicht', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
                     await   this.setState(prevState => ({
                            variations: [...prevState.variations, changedVariant],
                            changeVariantText:'',
                            changeVariantPrice:'',
                            changeVariantStock:0,
                        }))
          

            }

           else if(this.state.quantity === true){
                await this.setState({
                    variations: this.state.variations.filter((object) => object.id !== item.id)
                })
               
        
                        let changedVariant = {id: item.id,type:'Hoeveelheid', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
                     await   this.setState(prevState => ({
                            variations: [...prevState.variations, changedVariant],
                            changeVariantText:'',
                            changeVariantPrice:'',
                            changeVariantStock:0,
                        }))
          

            }

            else if(this.state.measure === true){
                await this.setState({
                    variations: this.state.variations.filter((object) => object.id !== item.id)
                })
               
        
                        let changedVariant = {id: item.id,type:'Maat', name: this.state.changeVariantText? this.state.changeVariantText: item.name, price: this.state.changeVariantPrice? this.state.changeVariantPrice: item.price, stock: this.state.changeVariantStock? this.state.changeVariantStock :item.stock};
                     await   this.setState(prevState => ({
                            variations: [...prevState.variations, changedVariant],
                            changeVariantText:'',
                            changeVariantPrice:'',
                            changeVariantStock:0,
                        }))
          

            }

            this.setState({
                changeVariantText:'',
                changeVariantPrice:'',
                changeVariantStock:0,
            })

    }

    changeVariant(variant){
        console.log(variant);
        if(variant === 'Kleur'){
            this.setState({
                variant:variant,
                color:true,
                weight:false,
                quantity:false,
                measure:false
            })
        }else if(variant === 'Gewicht'){
            this.setState({
                variant:variant,
                color:false,
                weight:true,
                quantity:false,
                measure:false
            })
        }else if(variant === 'Hoeveelheid'){
            this.setState({
                variant:variant,
                color:false,
                weight:false,
                quantity:true,
                measure:false
            })
        }else if(variant === 'Maat'){
            this.setState({
                variant:variant,
                color:false,
                weight:false,
                quantity:false,
                measure:true
            })
        }
    }


    

    render() {
        return (
            <div>
                <Toaster />
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                    <Link to={{pathname: `/producten`}}>Terug</Link>
                    <Button className="main-save-button" onClick={()=>this.changeProduct()}>Opslaan</Button>
                    {/*<Link className="align-button-right">Product toevoegen</Link>*/}
                </Col>
                <Col sm={8} className="colum-left">
                    <Row>
                        <Col sm={12} className="add-product-colum">
                            <label>Productnaam</label><br/>
                            <input type="text" value={this.state.name} onChange={(event)=>this.setState({name: event.target.value})}/><br/><br/>
                            <label>Product omschrijving</label><br/>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.productDesc}
                                
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    this.setState({productDesc: data})
                                } }

                            />
                            
                        </Col>
                        <Col sm={12} className="add-product-colum">
                            <label>Media</label><br/>
                            <input type="file" accept="image/gif, image/jpeg, image/png" onChange={(event) => this.handleChange(event)} /><Button className="add-button-settings" onClick={()=>this.handleUpload()}>Voeg afbeelding toe</Button> <br/><br/>
                            <label>Geselecteerde product foto's</label>
                            {this.state.imageAvailable ?
                                <Row>
                                {this.state.images.map((image)=>(
                                <Col sm={4} className="product-images">
                                    <span className="delete-image button-list button-onimage" >
                                        <Button onClick={()=>this.deleteImage(image.id)}>Verwijder</Button>
                                    </span>
                                    <img src={image.url}/>
                                </Col>
                                ))}
                            </Row>
                            :
                            <Row>
                                <Col sm={12} className="nothing-present">
                                    <span>Geen afbeeldingen geselecteert</span>
                                </Col>
                            </Row>
                            }
                           
                        </Col>
                        {this.state.activeVariations ?null:<Col sm={12} className="add-product-colum">
                            <label>Prijs per stuk incl. BTW</label><br/>
                            <span className="input-price">€ <input type="text" value={this.state.price} onChange={(event)=>this.setState({price: event.target.value})}/> </span><br/><br/>
                            <label>Voorraad</label><br/>
                            <input type="number" value={this.state.stok} onChange={(event)=>this.setState({stock: event.target.value})}/><br/><br/>
                            <label>BTW-percentage</label><br/>
                            <select value={this.state.btwValue} onChange={(event)=>this.setState({btwValue: event.target.value})}>
                            <option selected="selected" disabled="disabled">Selecteer een BTW percentage</option>
                                <option value="0">0%</option>
                                <option value="6">6%</option>
                                <option value="9">9%</option>
                                <option value="21">21%</option>
                            </select>
                        </Col>}
                        <Col sm={12} className="add-product-colum">
                            <label>Varianten</label><br/>
                            <input type="checkbox" checked={this.state.activeVariations} onChange={(event)=> this.handleaVariations(event.target.value)}/><label>Dit product heeft meerdere mogelijkheden, zoals verschillende maten of kleuren</label>
                           
                         {this.state.activeVariations ?   
                         <div><hr/>
                         <label>Type variant</label><br/>
                          <select value={this.state.variant} onChange={(event)=> this.changeVariant(event.target.value)}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een variant</option>
                                <option value="Kleur">Kleur</option>
                                <option value="Gewicht">Gewicht</option>
                                <option value="Hoeveelheid">Hoeveelheid</option>
                                <option value="Maat">Maat</option>
                            </select>
                            <label>BTW-percentage</label><br/>
                            <select value={this.state.btwValue} onChange={(event)=>this.setState({btwValue: event.target.value})}>
                            <option selected="selected" disabled="disabled">Selecteer een BTW percentage</option>
                                <option value="0">0%</option>
                                <option value="6">6%</option>
                                <option value="9">9%</option>
                                <option value="21">21%</option>
                            </select>
                            <label>Opties</label><br/>
                            {this.state.variations.sort((a, b) => (a.id > b.id) ? 1 : -1).map((item)=>(
                            <Row className="variations-row">
                                <Col sm={3}>
                                    <label>Optie</label>
                                    <input type="text" placeholder={item.name} onChange={(event)=> this.setState({changeVariantText: event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Prijs per stuk</label>
                                    <input type="text" placeholder={item.price}  onChange={(event)=> this.setState({changeVariantPrice: event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Voorraad</label>
                                    <input type="number" placeholder={item.stock}  onChange={(event)=> this.setState({changeVariantStock: event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <Button className="add-variation" onClick={()=>this.changeVariantProduct(item)}>Wijzig variant</Button>
                                </Col>
                            </Row>
                            ))}

                            {this.state.color ?
                            <Row className="variations-row">
                                <Col sm={3}>
                                    <label>Kleur</label>
                                    <input type="text" value={this.state.addVariantText} onChange={(event)=>this.setState({addVariantText:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Prijs per stuk</label>
                                    <input type="text" value={this.state.addVariantPrice} onChange={(event)=>this.setState({addVariantPrice:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Voorraad</label>
                                    <input type="number" value={this.state.addVariantStock} onChange={(event)=>this.setState({addVariantStock:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <Button className="add-variation" onClick={()=>this.addVariation()}>Voeg variant toe</Button>
                                </Col>
                            </Row>
                            :null}
                             {this.state.weight ?
                            <Row className="variations-row">
                                <Col sm={3}>
                                    <label>Gewicht</label>
                                    <input type="text" value={this.state.addVariantText} onChange={(event)=>this.setState({addVariantText:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Prijs per stuk</label>
                                    <input type="text" value={this.state.addVariantPrice} onChange={(event)=>this.setState({addVariantPrice:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Voorraad</label>
                                    <input type="number" value={this.state.addVariantStock} onChange={(event)=>this.setState({addVariantStock:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <Button className="add-variation" onClick={()=>this.addVariation()}>Voeg variant toe</Button>
                                </Col>
                            </Row>
                            :null}
                            {this.state.quantity ?
                            <Row className="variations-row">
                                <Col sm={3}>
                                    <label>Hoeveelheid</label>
                                    <input type="text" value={this.state.addVariantText} onChange={(event)=>this.setState({addVariantText:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Prijs per stuk</label>
                                    <input type="text" value={this.state.addVariantPrice} onChange={(event)=>this.setState({addVariantPrice:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Voorraad</label>
                                    <input type="number" value={this.state.addVariantStock} onChange={(event)=>this.setState({addVariantStock:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <Button className="add-variation" onClick={()=>this.addVariation()}>Voeg variant toe</Button>
                                </Col>
                            </Row>
                            :null}
                             {this.state.measure ?
                            <Row className="variations-row">
                                <Col sm={3}>
                                    <label>Maat</label>
                                    <input type="text" value={this.state.addVariantText} onChange={(event)=>this.setState({addVariantText:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Prijs per stuk</label>
                                    <input type="text" value={this.state.addVariantPrice} onChange={(event)=>this.setState({addVariantPrice:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <label>Voorraad</label>
                                    <input type="number" value={this.state.addVariantStock} onChange={(event)=>this.setState({addVariantStock:event.target.value})}/>
                                </Col>
                                <Col sm={3}>
                                    <Button className="add-variation" onClick={()=>this.addVariation()}>Voeg variant toe</Button>
                                </Col>
                            </Row>
                            :null}
                        
                            </div>:null}
                            
                        </Col>
                        <Col sm={12} className="last-col add-product-colum">
                        < hr/>
                        <Button onClick={()=>this.changeProduct()}>Opslaan</Button>
                        </Col>

                    </Row>
                </Col>
            
                <Col sm={4}>
                    <Row>
                        <Col sm={12} className="add-product-colum right-product-add-colom">
                            <label>Productstatus</label><br/>
                            <select value={this.state.status} onChange={(event)=>this.setState({status: event.target.value})}>
                                <option value="concept">Concept</option>
                                <option value="active">Actief</option>
                            </select>
                            <span>Dit product is momenteel alleen zichtbaar voor jouw.</span>
                        </Col>
                        <Col sm={12} className="add-product-colum right-product-add-colom categories-products">
                            <label>Categorieën</label><br/>
                            <select value={this.state.cat} onChange={(event)=>this.setState({cat: event.target.value})}>
                                <option value="Eten en drinken">Eten & Drinken</option>
                                <option value="Sieraden">Sieraden</option>
                                <option value="Kleding">Kleding</option>
                            </select>
                            <br/>
                            <Button onClick={()=>this.addCat()}>Categorie toevoegen</Button><br/><br/>
                            <br/>

                            <label>Geselecteerde categorien</label><br/>
                            {this.state.catActive?
                            <ul className="button-list">
                                {this.state.categories.map((item)=>(
                                    <li>{item.name}  <Button   onClick={()=>this.deleteCat(item.id)}>Verwijder</Button></li>
                                ))}
                               
                                
                            </ul>
                            :<span>Geen categorien geselecteert</span>}
                            
                        </Col>
                        <Col sm={12} className="add-product-colum right-product-add-colom categories-products">
                            <label>Tags</label><br/>
                            
                            <select value={this.state.tag} onChange={(event)=>this.setState({tag: event.target.value})}>
                            <option value="selected" selected="selected" disabled="disabled">Selecteer een tag</option>
                                <option value="Eten en drinken">Eten & Drinken</option>
                                <option value="Sieraden">Sieraden</option>
                                <option value="Kleding">Kleding</option>
                            </select><br/>
                            <Button onClick={()=>this.addTag()}>Maak tags aan</Button><br/><br/>
                            <br/>

                            <label>Geselecteerde tags</label><br/>
                            {this.state.tagActive?
                            <ul className="button-list" >
                                {this.state.tags.map((item)=>(
                                    <li>{item.name}  <Button  onClick={()=>this.deleteTag(item.id)}>Verwijder</Button></li>
                                ))}
                               
                                
                            </ul>
                            :<span>Geen tags geselecteert</span>}
                            
                        </Col>
                    </Row>
                </Col>
   
            </Row>
            </div>



        );
    }
};

export default ChangeProductsComponent;