import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, ModalLayout } from "./layouts";
import SignOnCompleteView from "./views/Authentication/SignOnCompleteView";
import SignOnView from "./views/Authentication/SignOnView";
import HomeView from "./views/frontend/HomeView";
import LoginScreen from "./views/LoginScreen";
import Message from "./views/Messages/MessageView";
import Orders from "./views/Orders/OrdersView";
import AddProductsView from "./views/Products/AddProductsView";
import ChangeProductsView from "./views/Products/ChangeProductsView";
import Products from "./views/Products/ProductsView";
import Profile from "./views/Profile/ProfileView";


// Route Views

import SignInScreen from "./views/signInView";



export default [
  {
    path: "/",
    exact: false,
    layout: ModalLayout,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/home",
    exact: false,
    layout: ModalLayout,
    component: HomeView
  },
  
];
