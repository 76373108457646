import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class BlockFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
         <Col sm={12} id="openingstijden">
                <Row>
                    <Col sm={3}>

                    </Col>
                    <Col sm={9} >
                       <Row className="footer-block" >
                           <Col sm={4}>
                               <h3>Openingstijden</h3>
                               <ul>
                                    <li><span className="left-day">MA </span> <span className="right-day">07:00 - 17:00</span></li>
                                    <li>DI <span className="right-day">07:00 - 17:00</span></li>
                                    <li>WO <span className="right-day">07:00 - 17:00</span></li>
                                    <li>DO <span className="right-day">07:00 - 17:00</span></li>
                                    <li>VR <span className="right-day">07:00 - 17:00</span></li>
                                    <li>ZA <span className="right-day">07:00 - 12:00</span></li>
                                </ul>
                           </Col>
                           <Col sm={4}>
                               <h3>Adres</h3>
                               <p>
                                    Binnenhavenweg 2<br />
                                    6171 DX, Stein
                                    
                               </p>
                           </Col>
                           <Col sm={4}>
                               <h3>Contact</h3>
                               <ul>
                                    <li>T. +31 (0)46 433 31 81</li>
                                    <li>E. ros@dirix.nl</li>
                                </ul>
                           </Col>
                       </Row>
                        
                    </Col>
                </Row>

        </Col>

        );
    }
};

export default BlockFooter;