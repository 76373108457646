import { TrendingDown } from '@material-ui/icons';
import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Footer from './footer';
import BlockFooter from './footer/footerBlock';
import SubFooter from './footer/subFooter';
import Header from './header';
import CenterColum from './header/centerColum';
import LeftColum from './header/leftColum';
import RightColum from './header/rightcolum';
import AfvoerenComponent from './homePage/AfvoerenComponent';
import AsnNummerComponent from './homePage/asnNummerComponent';
import BclComponent from './homePage/bclComponent';
import GrondbankComponent from './homePage/GrondbankComponent';
import MateriaalStortenHome from './homePage/materiaalStorten';
import OpoverslagComponent from './homePage/opoverslag';
import ProductenAfhalenComponent from './homePage/productenAfhalenComponent';
import RecyclingComponent from './homePage/RecyclingComponent';
import Services from './homePage/services';

class HomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            materials: false,
            afhalen:false,
            grond: false,
            recycling: false,
            overslag: false,
            afvoeren:false,
            asn:false,
            bcl: false,
            startMobile:0,
            mobileMenu:[{href: '#afval-storten',handler: 'afval',image:"materiaal-storten", title:'Afval storten'},{href: '#afhalen',handler: 'afhalen',image:"producten-afhalen", title:'Producten afhalen'},{href: '#grond',handler: 'grond',image:"grondbank", title:'Grondbank'},{href: '#recycling',handler: 'recycling',image:"recycling", title:'Recycling'},{href: '#overslag',handler: 'overslag',image:"op-overslag", title:'Op & overslag'},{href: '#bcl',handler: 'bcl',image:"bcl", title:'BCL'},{href: '#afvoeren',handler: 'afvoeren',image:"grond-afvoeren", title:'Grond afvoeren'},{href: '#asn',handler: 'asn',image:"afvalstroom", title:'ASN opvragen'}]

        };

    }

   

    

    handler(item) {

        if(item == 'afval'){
            this.setState({
                materials: true,
                afhalen:false,
                grond: false,
                recycling: false,
                overslag: false,
                bcl: false,
                afvoeren:false,
                asn:false
            })
        }else if(item == 'afhalen'){
            this.setState({
                materials: false,
                afhalen:true,
                grond: false,
                recycling: false,
                overslag: false,
                bcl: false,
                afvoeren:false,
                asn:false
            })
        }else if(item == 'grond'){
            this.setState({
                materials: false,
                afhalen:false,
                grond: true,
                recycling: false,
                overslag: false,
                bcl: false,
                afvoeren:false,
                asn:false
            })
        }else if(item == 'recycling'){
            this.setState({
                materials: false,
                afhalen:false,
                grond: false,
                recycling: true,
                overslag: false,
                bcl: false,
                afvoeren:false,
                asn:false
            })
        }
        else if(item == 'overslag'){
            this.setState({
                materials: false,
                afhalen:false,
                grond: false,
                recycling: false,
                overslag: true,
                bcl: false,
                afvoeren:false,
                asn:false
            })
        }else if(item == 'bcl'){
            this.setState({
                materials: false,
                afhalen:false,
                grond: false,
                recycling: false,
                overslag: false,
                bcl: true,
                afvoeren:false,
                asn:false
            })
        }else if(item == 'afvoeren'){
            this.setState({
                materials: false,
                afhalen:false,
                grond: false,
                recycling: false,
                overslag: false,
                bcl: false,
                afvoeren:true,
                asn:false
            })
        }else if(item == 'asn'){
            this.setState({
                materials: false,
                afhalen:false,
                grond: false,
                recycling: false,
                overslag: false,
                bcl: false,
                afvoeren:false,
                asn:true
                
            })
        }


      }

      backMobile(openItem){
        console.log(this.state.startMobile-1)

      if(this.state.startMobile - 1 < 0){
          this.setState({startMobile: 7})
          this.handler(this.state.mobileMenu[7].handler)
    
            this.setState({startMobile: 7})
      }  else{
        let nextItem = openItem -1;

        this.handler(this.state.mobileMenu[nextItem].handler)
          this.setState({startMobile:this.state.startMobile-1})
      }
     
    }

      forwardMobile(openItem){
          console.log(openItem+1)



        if(this.state.startMobile +1 < 8){
            let nextItem = openItem +1;

            this.handler(this.state.mobileMenu[nextItem].handler)
            this.setState({startMobile: this.state.startMobile+1})
        }  else{


            this.handler(this.state.mobileMenu[0].handler)
            this.setState({startMobile:0})
        }
       
      }



    render() {
        return (
            <Row>
                <Header />
                <Col sm={12} className="mobile">
   
                        <div className="back" onClick={()=> this.backMobile(this.state.startMobile)}>
                            <span><i class="fas fa-arrow-circle-left"></i></span>
                        </div>
                        <div className="item">
                         
                            
                            
                        <a href={this.state.mobileMenu[this.state.startMobile].href}  onClick={()=>this.handler(this.state.mobileMenu[this.state.startMobile].handler)}>
                        <img src={require('../../assets/images/svg/'+this.state.mobileMenu[this.state.startMobile].image+'.svg')} />
                        <h2>{this.state.mobileMenu[this.state.startMobile].title}</h2>
                    </a>
                        </div>
                        <div className="forward" onClick={()=>this.forwardMobile(this.state.startMobile)}>
                            <span><i class="fas fa-arrow-circle-right"></i></span>
                        </div>
                  
                   
                </Col>
                <Col  sm={2} className="left-colum desktop-sides" style={{height: window.innerHeight+150}}>
                    
                    <Row>
                        <Col sm={12} className="menu-item">
                        <a href="#afval-storten" onClick={()=>this.handler('afval')}>
                        <img src={require('../../assets/images/svg/materiaal-storten.svg')} />
                            <p>
                                Afval storten
                            </p>
                        </a>
                        </Col>
                        <Col sm={12} className="menu-item">
                        <a href="#afhalen" onClick={()=>this.handler('afhalen')}>
                        <img src={require('../../assets/images/svg/producten-afhalen.svg')} />
                            <p>
                                Producten afhalen
                            </p>
                        </a>
                        </Col>
                        <Col sm={12} className="menu-item" onClick={()=>this.handler('grond')}>
                        <a href="#grond" >
                        <img src={require('../../assets/images/svg/grondbank.svg')} />
                            <p>
                                Grondbank
                            </p>
                        </a>
                        </Col>
                        <Col sm={12} className="menu-item" onClick={()=>this.handler('afvoeren')}>
                        <a href="#afvoeren" >
                        <img src={require('../../assets/images/svg/grond-afvoeren.svg')} />
                            <p>
                                Grondafvoeren
                            </p>
                        </a>
                        </Col>

                    </Row>
                </Col>
                
                <CenterColum />
                <Col  sm={2} className="left-colum desktop-sides" style={{height: window.innerHeight+150}}>
                    <Row>
                        <Col sm={12} className="menu-item">
                        <a href="#recycling" onClick={()=>this.handler('recycling')}>
                        <img src={require('../../assets/images/svg/recycling.svg')} />
                            <p>
                                Recycling
                            </p>
                        </a>
                        </Col>
                        <Col sm={12} className="menu-item" >
                        <a href="#overslag" onClick={()=>this.handler('overslag')}>
                        <img src={require('../../assets/images/svg/op-overslag.svg')} />
                            <p>
                                Op & overslag
                            </p>
                        </a>
                        </Col>
                        <Col sm={12} className="menu-item">
                        <a href="#bcl" onClick={()=>this.handler('bcl')}>
                        <img src={require('../../assets/images/svg/bcl.svg')} />
                            <p>
                                BCL
                            </p>
                        </a>
                        </Col>
                        <Col sm={12} className="menu-item">
                        <a href="#asn" onClick={()=>this.handler('asn')}>
                        <img src={require('../../assets/images/svg/afvalstroom.svg')} />
                            <p>
                                ASN aanvragen
                            </p>
                        </a>
                        </Col>

                    </Row>
                </Col>

                <Col sm={12} className="desktop-sides">
                <video autoPlay muted loop id="myVideo">
                    <source src={require('../../assets/images/ros.mp4')} type="video/mp4" />
                </video>
                </Col>

                



                {this.state.materials ? <MateriaalStortenHome /> :null}
                {this.state.afhalen ? <ProductenAfhalenComponent />:null}
                {this.state.grond ?
                
                <Col sm={12} className="second-home-section" id="grond">
               <Row>
                   <Col sm={6} className="second-home-text-section">
                       <h2>GRONDBANK (BRL9335-1)</h2>
                       <img className="hr-line" src={require('../../assets/images/svg/hr-line.svg')} />
                       <p>
                       Dirix Elsloo heeft op haar locatie Recycling Overslag Stein (ROS) in Stein een Grondbank
                        Hiertoe is de locatie ROS van Dirix gecertificeerd conform BRL9335-1.
                        In deze gecertificeerde  Grondbank kan Dirix op de locatie ROS alle grond accepteren die voldoet aan de volgend eisen:
                        <ul>
                        <li>Grond is onverdacht; </li>
                        <li>Komt de grond vrij uit een tuin of anderszins niet van onder een verharding dan kan de grond worden beoordeeld o.b.v. de bodemkwaliteitskaart;</li>
                        <li>Komt de grond vrij van onder een verharding dan moet er eerst een verkennend bodem (vbo) onderzoek worden uitgevoerd. Aan de hand van dit vbo wordt door Dirix beoordeeld of de grond kan worden geaccepteerd in de Grondbank;</li>
                        <li>Alle grond wordt vooraf gescreend door Dirix;</li>
                        <li>Grond kan worden aangevoerd als deze door Dirix is goedgekeurd.</li>
                        </ul>

                        Wilt u zelf grond afvoeren? Klik op onderstaande link en we helpen u graag verder.

                       </p>
                       <a className="ros-button-cta" href="#afvoeren" onClick={()=>this.handler('afvoeren')}>
                           Afvoeren van grond
                       </a>


                   </Col>
                   <Col sm={6} className="second-home-image-section">
                        <img src={require('../../assets/images/materialen/grondbank.jpg')} />
                    </Col>

               </Row>
           </Col>
                :null}
                {this.state.recycling ?<RecyclingComponent />:null}
                {this.state.overslag ?
                <Col sm={12} className="second-home-section" id="overslag">
                <Row>
                    <Col sm={6} className="second-home-text-section">
                        <h2>OP- EN OVERSLAG</h2>
                        <img className="hr-line" src={require('../../assets/images/svg/hr-line.svg')} />
                        <p>
                        Dirix beschikt over een vergunde locatie van ca. 4 hectare, oftewel Recycling Overslag Stein. Deze locatie kan Dirix diverse afvalstoffen accepteren voor tijdelijke op- en overslag of eindverwerking. Indien u wilt weten of uw afvalstof geaccepteerd wordt, bekijken dan <a href="#afval-storten" onClick={()=>this.handler('afval')}>hier</a> de lijst. Voor tarieven kunt u contact met ons opnemen.  
                        </p>
                        <a className="ros-button-cta" target="_blank" href="https://www.dirix.nl/aan-de-slag/#Contact-opnemen">
                            Contact opnemen
                        </a>
 
 
                    </Col>
                    <Col sm={6} className="second-home-image-section">
                         <img src={require('../../assets/images/materialen/overslag.jpg')} />
                     </Col>
                     <Col sm={12} className="table">
                     <iframe width="560" height="315" src="https://www.youtube.com/embed/ZiwoI9iTGBo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                     
                        
                     </Col>
                </Row>
            </Col>
                :null}
                {this.state.bcl ?<BclComponent />:null}
                {this.state.afvoeren ?<AfvoerenComponent />:null}
                {this.state.asn ?<AsnNummerComponent />:null}

              {/*  <Services />*/}

               {/*} <BlockFooter />*/}
                <Col sm={12}>
             <Row className="footer-bg">
             
                <Col sm={3} className="footer">
                    <h3>Sitemap</h3>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Diensten</a></li>
                        <li><a href="#openingstijden">Openingstijden</a></li>
                        <li><a href="#openingstijden">Contact</a></li>
                    </ul>

                </Col>                
                <Col sm={3} className="footer" id="openingstijden">
                    <h3>Openingstijden</h3>
                    <ul>
                    <li><span className="left-day">MA </span> <span className="right-day">07:00 - 17:00</span></li>
                                    <li>DI <span className="right-day">07:00 - 17:00</span></li>
                                    <li>WO <span className="right-day">07:00 - 17:00</span></li>
                                    <li>DO <span className="right-day">07:00 - 17:00</span></li>
                                    <li>VR <span className="right-day">07:00 - 17:00</span></li>
                                    <li>ZA <span className="right-day">07:00 - 12:00</span></li>
                    </ul>
                    
                </Col>
                <Col sm={3} className="footer">
                    <h3>Diensten</h3>
                    <ul>
                        <li><a href="#afval-storten" onClick={()=>this.handler('afval')}>Afval Storten</a></li>
                        <li><a href="#afhalen" onClick={()=>this.handler('afhalen')}>Producten Afhalen</a></li>
                        <li><a href="#grond" onClick={()=>this.handler('grond')}>Grondbank</a></li>
                        <li><a href="#recycling" onClick={()=>this.handler('recycling')}>Recycling</a></li>
                        <li><a href="#overslag" onClick={()=>this.handler('overslag')}>Op & Overslag</a></li>
                        <li><a href="#bcl" onClick={()=>this.handler('bcl')}>BCL</a></li>
                    </ul>
                    
                </Col>
                <Col sm={3} className="footer">
                    <img src={require('../../assets/images/huisstijl/ros-logo.png')} />

                    <p>
               
                    Binnenhavenweg 2<br/>
                    6171 DX Stein<br/><br/>

                    T. +31 (0)46 433 31 81<br/>
                    E. ros@dirix.nl<br/>
                    </p>
                    
                </Col>
                </Row>

                </Col>
                <SubFooter />

                


                
                
            </Row>
        );
    }
};

export default HomeComponent;