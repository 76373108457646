import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openingstijden:[{time:'Gesloten'},{time:'07.00 - 17.00 uur'},{time:'07.00 - 17.00 uur'},{time:'07.00 - 17.00 uur'},{time:'Gesloten'},{time:'07.00 - 17.00 uur'},{time:'07.00 - 12.00 uur'}],
            day: 0,
            open:true,
        };
    }

    componentDidMount(){
        let date = new Date();
        let getDay =date.getDay();
        this.setState({
            day: getDay
        })

        if(date.getDay() <1){
            this.setState({
                open:false,
            })
        }
  
        console.log(date.getDay())


    }





    render() {
        return (
            <Row className="header ">
                <Col  sm={3} className="header-left">
                    <a href="#openingstijden" className="smoot-link" >
                    {this.state.open ? 
                    <p>
                        Vandaag geopend:<br/>
                        {this.state.openingstijden[this.state.day].time}
                    </p>
                    :
                    <p>
                    Vandaag is ROS:<br/>
                    {this.state.openingstijden[this.state.day].time}
                     </p>
                    }
                    </a>
                </Col>
                <Col sm={6} className="header-center">
                        <img src={require('../../../assets/images/huisstijl/ros-logo.png')} />
                </Col>
                <Col  sm={3} className="header-right">
                    <p>
                        <a target="_blank" href="https://goo.gl/maps/1c4AzV89cL3iKx8a9">Binnenhavenweg 2<br/>
                        6171 DX Stein</a><br/>
                        +31 (0)46 433 31 81
                    </p>
                </Col>



           </Row>
           
        );
    }
};

export default Header;