import React, { Component,useState, useEffect  } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";



class RightColum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount(){
        console.log('hoogte is:'+window.innerHeight)
    }

    



    render() {
        return (
                <Col  sm={2} className="left-colum" style={{height: window.innerHeight+150}}>
                    <Row>
                        <Col sm={12} className="menu-item">
                        <a href="#recycling">
                        <img src={require('../../../assets/images/svg/recycling.svg')} />
                            <p>
                                Recycling
                            </p>
                        </a>
                        </Col>
                        <Col sm={12} className="menu-item">
                        <a href="#overslag">
                        <img src={require('../../../assets/images/svg/op-overslag.svg')} />
                            <p>
                                Op & overslag
                            </p>
                        </a>
                        </Col>
                        <Col sm={12} className="menu-item">
                        <a href="#bcl">
                        <img src={require('../../../assets/images/svg/bcl.svg')} />
                            <p>
                                BCL
                            </p>
                        </a>
                        </Col>

                    </Row>
                </Col>

  
        );
    }
};

export default RightColum;