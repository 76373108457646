import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
         <Col sm={12}>
             <Row >
             
                <Col sm={3} className="footer">
                    <h3>Sitemap</h3>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Diensten</a></li>
                        <li><a href="#">Openingstijden</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul>

                </Col>                
                <Col sm={3} className="footer">
                    <h3>Openingstijden</h3>
                    <ul>
                    <li><span className="left-day">MA </span> <span className="right-day">07:00 - 17:00</span></li>
                                    <li>DI <span className="right-day">07:00 - 17:00</span></li>
                                    <li>WO <span className="right-day">07:00 - 17:00</span></li>
                                    <li>DO <span className="right-day">07:00 - 17:00</span></li>
                                    <li>VR <span className="right-day">07:00 - 17:00</span></li>
                                    <li>ZA <span className="right-day">07:00 - 12:00</span></li>
                    </ul>
                    
                </Col>
                <Col sm={3} className="footer">
                    <h3>Diensten</h3>
                    <ul>
                        <li><a href="#">Materialen Storten</a></li>
                        <li><a href="#">Producten Afhalen</a></li>
                        <li><a href="#grond" onClick={()=>this.handler('grond')}>Grondbank</a></li>
                        <li><a href="#">Recycling</a></li>
                        <li><a href="#">Op & Overslag</a></li>
                        <li><a href="#">BCL</a></li>
                    </ul>
                    
                </Col>
                <Col sm={3} className="footer">
                    <img src={require('../../../assets/images/huisstijl/ros-logo.png')} />

                    <p>
                    ROS Stein<br/>
                    Binnenhavenweg 2<br/>
                    6171 DX  Stein<br/><br/>

                    T. +31 (0)46 433 31 81<br/>
                    E. info@rosstein.nl<br/>
                    </p>
                    
                </Col>
                </Row>

                </Col>

        );
    }
};

export default Footer;