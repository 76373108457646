import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";

class RecyclingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    render() {
        return (
           <Col sm={12} className="second-home-section" id="recycling">
               <Row>
                   <Col sm={6} className="second-home-text-section">
                       <h2>RECYCLING</h2>
                       <img className="hr-line" src={require('../../../assets/images/svg/hr-line.svg')} />
                       <p>
                       Dirix is in het bezit van een eigen mobiele breker. Deze is gecertificeerd voor het produceren van diverse puin (BRL2506). Dirix produceert bij Recycling Overslag Stein op certificaat onder andere: asfaltgranulaat, betongranulaat, menggranulaat en betongranulaat in div. graderingen. Klik hier voor de lijst met <a href="https://www.dirix.nl/certificaten-en-downloads/">gecertificeerde producten</a>. Overige afvalstoffen verwerken wij niet, maar voeren wij in grote bulk hoeveelheden af naar afvalverwerkers in de gehele Benelux. De breker kan ook bij u op locatie ingezet worden. Neem contact op voor de mogelijkheden. 
                       </p>
                       <a className="ros-button-cta" target="_blank" href="https://www.dirix.nl/aan-de-slag/#Contact-opnemen">
                           Contact opnemen
                       </a>


                   </Col>
                   <Col sm={6} className="second-home-image-section">
                        <img src={require('../../../assets/images/materialen/dummy-image.jpg')} />
                    </Col>
                    <Col sm={12} className="table">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ZiwoI9iTGBo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
                       
                    </Col>
               </Row>
           </Col>
        );
    }
};

export default RecyclingComponent;